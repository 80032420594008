import { getUseCase } from '@lib/plugin-redux-core';
import {
  EssayQuestion,
  FillInBlankQuestion,
  FindQuestionsUseCaseQueryParams,
  FIND_ALL_QUESTIONS_USE_CASE,
  MultipleChoiceQuestion,
  Question,
  QuestionProps,
  QuestionType,
  ResponseType,
  Section,
  SingleChoiceQuestion,
} from '@module/form';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useCallback, ReactNode, useMemo } from 'react';
import {
  loadingQuestionsSelector,
  questionsSelector,
  selectedSectionSelector,
} from '../../../redux/quiz-preview';
import { EssayQuestionPreviewContainer } from './essay-preview.container';
import { SingleChoiceQuestionPreviewContainer } from './single-choice-preview.container';
import { MultipleChoiceQuestionPreviewContainer } from './multiple-choice-preview.container';
import { FillInBlankQuestionPreviewContainer } from './fill-in-blank-preview.container';
import { Skeleton, Space } from 'antd';
import { SubmissionQuestionLabel } from '@module/form-components';
import { useTranslation } from 'next-i18next';
import { styled } from '@xstyled/styled-components';

const ContentWrapper = styled(Space)`
  width: 100%;

  & > .ant-space-item:last-child {
    margin-bottom: 150px;
  }
`;

export const QuestionsPreviewContainer = () => {
  // Define configs
  const dispatch = useDispatch();
  const { t: translation } = useTranslation('common');
  const label: SubmissionQuestionLabel = useMemo(() => {
    return {
      questionNumber: translation('questionNumber'),
      questionTitle: translation('questionTitle'),
      score: translation('score'),
    };
  }, [translation]);

  // Get Selector
  const selectedSection: Section = useSelector(selectedSectionSelector);
  const questions: Question<QuestionProps>[] = useSelector(questionsSelector);
  const isLoadingQuestions: boolean = useSelector(loadingQuestionsSelector);

  const executeFindQuestionsUseCase = useCallback(() => {
    const findQuestionsUseCase = getUseCase(
      FIND_ALL_QUESTIONS_USE_CASE,
      dispatch,
    );
    const findQuestionsUseCaseInput: FindQuestionsUseCaseQueryParams = {
      sectionId: selectedSection.id.toString(),
      type: ResponseType.All,
    };
    findQuestionsUseCase.execute(findQuestionsUseCaseInput);
  }, [dispatch, selectedSection.id]);

  useEffect(() => {
    executeFindQuestionsUseCase();
  }, [executeFindQuestionsUseCase]);

  const renderQuestionPreviewFactory = (
    question: Question<QuestionProps>,
    index: number,
  ): ReactNode => {
    switch (question.type) {
      case QuestionType.Essay:
        return (
          <EssayQuestionPreviewContainer
            question={question as EssayQuestion}
            index={index}
            label={label}
          />
        );

      case QuestionType.SingleChoice:
        return (
          <SingleChoiceQuestionPreviewContainer
            question={question as SingleChoiceQuestion}
            index={index}
            label={label}
          />
        );
      case QuestionType.MultipleChoice:
        return (
          <MultipleChoiceQuestionPreviewContainer
            question={question as MultipleChoiceQuestion}
            index={index}
            label={label}
          />
        );
      case QuestionType.FillInBlank:
      default:
        return (
          <FillInBlankQuestionPreviewContainer
            question={question as FillInBlankQuestion}
            index={index}
            label={label}
          />
        );
    }
  };

  const renderQuestions = (): ReactNode => {
    if (isLoadingQuestions === undefined || isLoadingQuestions === true) {
      return (
        <>
          <Skeleton active/>
          <Skeleton active/>
          <Skeleton active/>
          <Skeleton active/>
          <Skeleton active/>
          <Skeleton active/>
          <Skeleton active/>
          <Skeleton active/>
          <Skeleton active/>
        </>
      );
    }

    return (
      <ContentWrapper size="middle" direction="vertical">
        {questions.map((q, index) => renderQuestionPreviewFactory(q, index))}
      </ContentWrapper>
    );
  };

  return <>{renderQuestions()}</>;
};
