import { getUseCase } from '@lib/plugin-redux-core';
import {
  CreateEssayQuestionUseCaseInput,
  CreateFillBlankQuestionUseCaseInput,
  CreateMultipleChoiceQuestionUseCaseInput,
  CreateSectionUseCaseInput,
  CreateSingleChoiceQuestionUseCaseInput,
  CREATE_ESSAY_QUESTION_USE_CASE,
  CREATE_SINGLE_CHOICE_QUESTION_USE_CASE,
  CREATE_MULTIPLE_CHOICE_QUESTION_USE_CASE,
  QuestionType,
  CREATE_FILL_IN_BLANK_QUESTION_USE_CASE,
  CREATE_SECTION_USE_CASE,
  CREATE_QUESTION_USE_CASE,
  CreateQuestionUseCaseInput,
  CorrectAnswer,
  Answer,
  CorrectAnswerProps,
  EssayQuestion,
  SingleChoiceQuestion,
  MultipleChoiceQuestion,
  FillInBlankQuestion,
} from '@module/form';
import { AccessibilityBar } from '@module/form-components';
import { UniqueEntityID } from '@smart-form/domains';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import router from 'next/router';
import { useDebounce } from '@lib/common';

import { useTranslation } from 'next-i18next';
import {
  createdLocalQuestionStatusSelector,
  questionListSelector,
  QuizEditStatus,
  sectionQuantitySelector,
  sectionSelectedSelector,
} from '../../../../redux/quiz-edit';

export const CreateQuestionOrSectionContainer = (): ReactElement => {
  // Define state
  const [isAccessibilityBarVisible, setAccessibilityBarVisibility] = useState<boolean>(false);

  const handleDebounce = useDebounce();
  const { id: formId } = router.query;
  const dispatch = useDispatch();
  const { t: translation } = useTranslation('form');

  const sectionQuantity = useSelector(sectionQuantitySelector);
  const sectionSelected = useSelector(sectionSelectedSelector);
  const createdLocalQuestionStatus = useSelector(createdLocalQuestionStatusSelector);
  const questions = useSelector(questionListSelector);

  const onCreateSection = useCallback(() => {
    handleDebounce(() => {
      const createSectionUseCase = getUseCase(CREATE_SECTION_USE_CASE, dispatch);
      const sectionId = new UniqueEntityID().toString();
      const sectionFieldsInput = {
        id: sectionId,
        title: `Nhập tên phần ${sectionQuantity + 1}`,
        description: 'Nhập mô tả',
        score: 0,
        questions: [],
      };

      if (formId) {
        const createSectionUseCaseInput: CreateSectionUseCaseInput = {
          formId: formId.toString(),
          payload: sectionFieldsInput,
        };
        createSectionUseCase.execute(createSectionUseCaseInput);
      }
    });

    setAccessibilityBarVisibility(false);
  }, [dispatch, formId, handleDebounce, sectionQuantity]);

  const onCreateQuestion = useCallback(
    (questionType: QuestionType) => {
      if (!sectionSelected) return;
      handleDebounce(() => {
        switch (questionType) {
          case QuestionType.Essay: {
            const input: CreateEssayQuestionUseCaseInput = {
              description: translation('default_question_description'),
              formId: formId.toString(),
              sectionId: sectionSelected.id.toString(),
            };
            const createEssayQuestionUseCase = getUseCase(CREATE_ESSAY_QUESTION_USE_CASE, dispatch);
            createEssayQuestionUseCase.execute(input);
            break;
          }
          case QuestionType.SingleChoice: {
            const input: CreateSingleChoiceQuestionUseCaseInput = {
              description: translation('default_question_description'),
              formId: formId.toString(),
              sectionId: sectionSelected.id.toString(),
              answers: [
                translation('default_answer_description'),
                translation('default_answer_description'),
              ],
            };
            const createSingleChoiceQuestionUseCase = getUseCase(
              CREATE_SINGLE_CHOICE_QUESTION_USE_CASE,
              dispatch,
            );
            createSingleChoiceQuestionUseCase.execute(input);
            break;
          }
          case QuestionType.MultipleChoice: {
            const input: CreateMultipleChoiceQuestionUseCaseInput = {
              description: translation('default_question_description'),
              formId: formId.toString(),
              sectionId: sectionSelected.id.toString(),
              answers: [
                translation('default_answer_description'),
                translation('default_answer_description'),
                translation('default_answer_description'),
              ],
            };
            const createMultipleChoiceQuestionUseCase = getUseCase(
              CREATE_MULTIPLE_CHOICE_QUESTION_USE_CASE,
              dispatch,
            );
            createMultipleChoiceQuestionUseCase.execute(input);
            break;
          }
          case QuestionType.FillInBlank: {
            const input: CreateFillBlankQuestionUseCaseInput = {
              description: translation('default_question_description'),
              formId: formId.toString(),
              sectionId: sectionSelected.id.toString(),
              answers: [translation('default_answer_description')],
            };
            const createFillInBlankQuestionUseCase = getUseCase(
              CREATE_FILL_IN_BLANK_QUESTION_USE_CASE,
              dispatch,
            );
            createFillInBlankQuestionUseCase.execute(input);
            break;
          }
        }
      });
    },
    [dispatch, formId, handleDebounce, sectionSelected, translation],
  );

  useEffect(() => {
    if (createdLocalQuestionStatus !== QuizEditStatus.SUCCESS) return;

    const createQuestionUseCase = getUseCase(CREATE_QUESTION_USE_CASE, dispatch);

    let createQuestionUseCaseInput: CreateQuestionUseCaseInput;
    const question = questions[questions.length - 1];

    if (question.type === QuestionType.Essay) {
      const newQuestion = question as EssayQuestion;
      createQuestionUseCaseInput = {
        id: newQuestion.id.toString(),
        type: newQuestion.type,
        sectionId: newQuestion.sectionId,
        formId: newQuestion.formId,
        description: newQuestion.description,
      };
    } else {
      const newQuestion = question as
        | SingleChoiceQuestion
        | MultipleChoiceQuestion
        | FillInBlankQuestion;
      const answers = newQuestion.answers.map((answer: Answer) => {
        return {
          id: answer.id.toString(),
          description: answer.description,
        };
      });
      const correctAnswers = newQuestion.correctAnswers.map(
        (correctAnswer: CorrectAnswer<CorrectAnswerProps>) => {
          return {
            id: correctAnswer.id.toString(),
            answerId: correctAnswer.answerId,
          };
        },
      );
      createQuestionUseCaseInput = {
        id: newQuestion.id.toString(),
        type: newQuestion.type,
        sectionId: newQuestion.sectionId,
        formId: newQuestion.formId,
        description: newQuestion.description,
        answers,
        correctAnswers,
      };
    }
    createQuestionUseCase.execute(createQuestionUseCaseInput);
  }, [createdLocalQuestionStatus, dispatch, questions]);

  const handleTriggerVisibleChange = (visible: boolean) => {
    setAccessibilityBarVisibility(visible);
  };

  return (
    <AccessibilityBar
      onCreateQuestion={onCreateQuestion}
      onCreateSection={onCreateSection}
      visible={isAccessibilityBarVisible}
      onTriggerVisibleChange={handleTriggerVisibleChange}
    />
  );
};
