import { useDebounce } from '@lib/common';
import {
  FindQuestionsUseCaseQueryParams,
  FIND_QUESTION_SUMMARIES_USE_CASE,
  GetSectionSummaryInput,
  GET_SECTION_SUMMARIES_USE_CASE,
  QuestionSummary,
  ResponseType,
  SectionSummary,
} from '@module/form';
import { SectionSummaryComponent } from '@module/form-components';
import { getUseCase } from '@lib/plugin-redux-core';
import { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_SELECTED_SECTION_QUIZ_EDIT, QUESTION_FOCUS_USE_CASE } from 'redux/root.action';
import router from 'next/router';
import {
  questionSummariesSelector,
  findSectionSummariesSelector,
  selectedQuestionIndexSelector,
  findSectionSummariesStatusSelector,
  QuizEditStatus,
  sectionSelectedIndexSelector,
  updateSectionStatusSelector,
  deleteSectionStatusSelector,
  isLoadingGetQuestionsSelector,
} from '../../../../redux/quiz-edit';
import { Skeleton } from 'antd';
import styled from '@xstyled/styled-components';

const LoadingArea = styled.div`
  padding: 16px 24px;
`;

export const SectionSummaryContainer = (): ReactElement => {
  const { id: formId } = router.query;

  const sections = useSelector(findSectionSummariesSelector) as SectionSummary[];
  const findSectionSummariesStatus = useSelector(findSectionSummariesStatusSelector);
  const sectionSelectedIndex = useSelector(sectionSelectedIndexSelector);
  const questions: QuestionSummary[] = useSelector(questionSummariesSelector);
  const selectedQuestionIndex = useSelector(selectedQuestionIndexSelector);
  const isLoadingGetQuestionSummaries = useSelector(isLoadingGetQuestionsSelector);

  const updateSectionStatus = useSelector(updateSectionStatusSelector);
  const deleteSectionStatus = useSelector(deleteSectionStatusSelector);
  const dispatch = useDispatch();

  const [sectionsExpand, setSectionsExpand] = useState([sectionSelectedIndex]);
  const [listSection, setListSection] = useState(sections);

  const handleDebounce = useDebounce();
  const isLoading =
    findSectionSummariesStatus !== QuizEditStatus.SUCCESS || !sections || !listSection;

  const onSetQuestionFocus = useCallback(
    (index) => {
      dispatch({
        type: QUESTION_FOCUS_USE_CASE,
        payload: index,
      });
    },
    [dispatch],
  );

  const onGetSectionsSummary = useCallback(() => {
    const getSectionsSummaryUseCase = getUseCase(GET_SECTION_SUMMARIES_USE_CASE, dispatch);
    const getSectionsSummaryUseCaseInput: GetSectionSummaryInput = {
      formId: formId.toString(),
    };
    getSectionsSummaryUseCase.execute(getSectionsSummaryUseCaseInput);
  }, [dispatch, formId]);

  const onGetQuestionsSummary = useCallback(
    (sectionId: string, isSelected: boolean) => {
      const getQuestionsSummaryUseCase = getUseCase(FIND_QUESTION_SUMMARIES_USE_CASE, dispatch);
      const getQuestionsSummaryUseCaseInput: FindQuestionsUseCaseQueryParams = {
        sectionId,
        type: ResponseType.Summary,
        isSelected,
      };
      getQuestionsSummaryUseCase.execute(getQuestionsSummaryUseCaseInput);
    },
    [dispatch],
  );

  const onExpandSection = useCallback(
    (index) => {
      const sectionId = sections[index - 1].id;
      setSectionsExpand(sectionsExpand.includes(index) ? [] : [index]);
      handleDebounce(() => onGetQuestionsSummary(sectionId.toString(), true));
    },
    [handleDebounce, onGetQuestionsSummary, sections, sectionsExpand],
  );

  const onSelectSection = useCallback(
    (index) => {
      const sectionId = sections[index - 1].id.toString();
      dispatch({
        type: UPDATE_SELECTED_SECTION_QUIZ_EDIT,
        payload: {
          sectionSelectedIndex: index,
          sectionId,
        },
      });
      onExpandSection(index);
      handleDebounce(() => onGetQuestionsSummary(sectionId.toString(), true));
      onSetQuestionFocus('null');
    },
    [
      dispatch,
      handleDebounce,
      onExpandSection,
      onGetQuestionsSummary,
      onSetQuestionFocus,
      sections,
    ],
  );

  const onSelectQuestion = useCallback(
    (sectionIndex, questionIndex) => {
      if (sectionIndex !== sectionSelectedIndex) {
        onSelectSection(sectionIndex);
      }
      onSetQuestionFocus(questionIndex);
      setTimeout(() => {
        const questionPreviewElement = document.getElementById(`qp_${questionIndex}`);
        const questionEditElement = document.getElementById(`qe_${questionIndex}`);
        const mouseDownEvent = new MouseEvent('mousedown', {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        const scrollOptions: ScrollIntoViewOptions = {
          behavior: 'smooth',
          block: 'nearest',
        };
        questionPreviewElement && questionPreviewElement.dispatchEvent(mouseDownEvent);
        questionEditElement && questionEditElement.scrollIntoView(scrollOptions);
      });
    },
    [sectionSelectedIndex, onSetQuestionFocus, onSelectSection],
  );

  useEffect(() => {
    if (
      updateSectionStatus === QuizEditStatus.SUCCESS ||
      deleteSectionStatus === QuizEditStatus.SUCCESS
    ) {
      const listSectionUpdate = [...sections];
      setListSection(listSectionUpdate);
    }
  }, [deleteSectionStatus, questions, sections, updateSectionStatus]);

  useEffect(() => {
    const hasSectionsAndQuestions = sections && questions;
    if (hasSectionsAndQuestions) {
      const listSectionUpdate = [...sections];
      listSectionUpdate.forEach((section, index) => {
        const newestSectionQuestionList = questions.filter(
          (q) => q.sectionId === section.id.toString(),
        );
        const isSelectedSection = sectionSelectedIndex === index + 1;
        const newestTotalQuestionScore = newestSectionQuestionList.reduce(
          (partialSum, a) => partialSum + a.score,
          0,
        );
        if (isSelectedSection) {
          const isNotEmptyQuestions = newestSectionQuestionList.length !== 0;
          const isEmptyTotalQuestion = section.totalQuestion === 0;
          if (isNotEmptyQuestions || isEmptyTotalQuestion) {
            section.updateScore(newestTotalQuestionScore);
          }
        } else {
          section.updateScore(section.totalScore);
        }

        section.updateQuestions(newestSectionQuestionList);
      });
      setListSection(listSectionUpdate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, sections]);

  useEffect(() => {
    if (sections) {
      setSectionsExpand([sectionSelectedIndex]);
    }
  }, [isLoading, sectionSelectedIndex, sections]);

  useEffect(() => {
    onGetSectionsSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = (): ReactNode => {
    if (isLoading) {
      return (
        <LoadingArea>
          <Skeleton active paragraph={{ rows: 12 }} />
        </LoadingArea>
      );
    }

    const totalQuestionScore = listSection.reduce((partialSum, q) => partialSum + q.totalScore, 0);

    return (
      <SectionSummaryComponent
        sections={listSection}
        sectionsExpand={sectionsExpand}
        sectionSelectedIndex={sectionSelectedIndex}
        onSelectedSection={onSelectSection}
        onSelectedQuestion={onSelectQuestion}
        selectedQuestionIndex={selectedQuestionIndex}
        isLoadingGetSectionSummaries={isLoading}
        isLoadingGetQuestionSummaries={isLoadingGetQuestionSummaries}
        totalQuestionScore={totalQuestionScore}
      />
    );
  };

  return <>{renderContent()}</>;
};
