import { SingleChoiceQuestion } from '@module/form';
import {
  SubmissionQuestionLabel,
  SubmissionSingleChoiceQuestion,
} from '@module/form-components';

interface SingleChoiceQuestionPreviewContainerProps {
  question: SingleChoiceQuestion;
  index: number;
  label: SubmissionQuestionLabel;
}

export const SingleChoiceQuestionPreviewContainer = (
  props: SingleChoiceQuestionPreviewContainerProps,
) => {
  const { question, index, label } = props;

  return (
    <SubmissionSingleChoiceQuestion
      disabled
      label={label}
      question={question.toJsonObject()}
      index={index}
    />
  );
};
