import { FillInBlankQuestion } from '@module/form';
import {
  SubmissionFillInBlankQuestion,
  SubmissionQuestionLabel,
} from '@module/form-components';

interface FillInBlankQuestionPreviewContainerProps {
  question: FillInBlankQuestion;
  index: number;
  label: SubmissionQuestionLabel;
}
export const FillInBlankQuestionPreviewContainer = (
  props: FillInBlankQuestionPreviewContainerProps,
) => {
  const { question, index, label } = props;

  return (
    <SubmissionFillInBlankQuestion
      disabled
      label={label}
      question={question.toJsonObject()}
      index={index}
    />
  );
};
