import { EssayQuestion } from '@module/form';
import {
  SubmissionEssayQuestion,
  SubmissionQuestionLabel,
} from '@module/form-components';

interface EssayQuestionPreviewContainerProps {
  question: EssayQuestion;
  index: number;
  label: SubmissionQuestionLabel;
}
export const EssayQuestionPreviewContainer = (
  props: EssayQuestionPreviewContainerProps,
) => {
  const { question, index, label } = props;

  return (
    <SubmissionEssayQuestion
      disabled
      label={label}
      question={question.toJsonObject()}
      index={index}
    />
  );
};
