export enum MenuItemKey {
  Space = 'space',
  Assignment = 'assignment',
  Test = 'test',
  // Bottom menu item keys
  Help = 'help',
  Setting = 'setting',
  SignOut = 'signOut',
}

export * from './routes';
