export const routesMap = {
  home: '/',
  space: '/space',
  folder: '/folders',
  assignment: '/assignment',
  test: '/test',
  //Bottom menu routes mapping
  help: '/help',
  setting: '/setting',
};

export const DEFAULT_PATH = routesMap.space;
