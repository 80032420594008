import { showModalConfirm } from '@lib/common';
import { EssayQuestion, UpdateQuestionUseCaseInput, UPDATE_QUESTION_USE_CASE } from '@module/form';
import { Essay } from '@module/form-components';
import { getUseCase } from '@lib/plugin-redux-core';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sectionSelectedSelector, editingQuestionSelector } from 'redux/quiz-edit';
import { useTranslation } from 'next-i18next';

interface EssayQuestionContainerProps {
  question: EssayQuestion;
  index: number;
  onDelete: () => void;

  onUpdateType: (questionId: string, value: string) => void;
  onFocusQuestion: (index: number) => void;
}
export const EssayQuestionContainer = (props: EssayQuestionContainerProps) => {
  const { question, index, onDelete, onUpdateType, onFocusQuestion } = props;
  const sectionSelected = useSelector(sectionSelectedSelector);
  const editingQuestionId = useSelector(editingQuestionSelector);
  const dispatch = useDispatch();
  const { t: formTranslation } = useTranslation('form');

  const onUpdateQuestion = useCallback(
    (questionId, field, value) => {
      if ((!index && !field && !value) || !sectionSelected) return;
      const updateQuestionUseCase = getUseCase(UPDATE_QUESTION_USE_CASE, dispatch);
      const updateQuestionUseCaseInput: UpdateQuestionUseCaseInput = {
        id: questionId,
        input: {},
      };
      switch (field) {
        case 'score':
          updateQuestionUseCaseInput.input.score = parseFloat(value);
          break;
        case 'description':
          updateQuestionUseCaseInput.input.description = value;
          break;
        case 'explanation':
          updateQuestionUseCaseInput.input = {
            ...updateQuestionUseCaseInput.input,
            [field]: value,
          };
          break;
        default:
          return;
      }
      updateQuestionUseCase.execute(updateQuestionUseCaseInput);
    },
    [dispatch, index, sectionSelected],
  );

  const onChangeDeleteModal = useCallback(() => {
    showModalConfirm({
      title: `Xác nhận xoá câu hỏi số ${index + 1}`,
      okText: 'Xác nhận',
      cancelText: 'Huỷ',
      onOk: () => {
        onDelete();
      },
    });
  }, [index, onDelete]);

  return (
    <Essay
      question={question as EssayQuestion}
      index={index}
      editingQuestionId={editingQuestionId}
      //question
      onUpdateType={onUpdateType}
      onDelete={onChangeDeleteModal}
      onUpdate={onUpdateQuestion}
      onFocusQuestion={onFocusQuestion}
      defaultDescription={formTranslation('default_question_description')}
    />
  );
};
