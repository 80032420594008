import { showModalConfirm, useDebounce } from '@lib/common';
import {
  DeleteSectionUseCaseInput,
  DELETE_SECTION_USE_CASE,
  QuestionProps,
  SectionSummary,
  UpdateSectionInput,
  UpdateSectionUseCaseInput,
  UPDATE_SECTION_USE_CASE,
  Section,
} from '@module/form';

import { getUseCase } from '@lib/plugin-redux-core';
import router from 'next/router';
import { ReactNode, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { Question } from '@module/form';

import { SectionFactory } from '@module/form-components';
import {
  questionListSelector,
  sectionSelectedIndexSelector,
  sectionSelectedSelector,
  selectedSectionDetailSelector,
  isLoadingGetQuestionsSelector,
  findSectionSummariesSelector,
} from '../../../../redux/quiz-edit';
import { useTranslation } from 'next-i18next';
import { Skeleton } from 'antd';

const SectionDetailWrapper = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const SectionDetailContainer = () => {
  const { t: translation } = useTranslation('common');
  const sectionSelected: SectionSummary = useSelector(sectionSelectedSelector);
  const selectedSectionDetail: Section = useSelector(
    selectedSectionDetailSelector,
  );
  const sectionSelectedIndex = useSelector(sectionSelectedIndexSelector);

  const isLoadingGetQuestions = useSelector(isLoadingGetQuestionsSelector);

  const sections: SectionSummary[] = useSelector(findSectionSummariesSelector);

  const questions = useSelector(questionListSelector);
  const handleDebounce = useDebounce();
  const { id: formId } = router.query;
  const dispatch = useDispatch();

  const onDeleteSection = useCallback(() => {
    const deleteSectionUseCase = getUseCase(
      DELETE_SECTION_USE_CASE,
      dispatch,
      translation,
    );
    if (!formId) return;
    const deleteSectionUseCaseInput: DeleteSectionUseCaseInput = {
      formId: formId.toString(),
      sectionId: sectionSelected.id.toString(),
    };
    deleteSectionUseCase.execute(deleteSectionUseCaseInput);
  }, [dispatch, formId, sectionSelected, translation]);

  const handleDeleteSectionConfirm = useCallback(() => {
    showModalConfirm({
      title: `Xác nhận xóa phần ${sectionSelected.index}`,
      okText: translation('confirm'),
      cancelText: translation('cancel'),
      onOk: onDeleteSection,
    });
  }, [sectionSelected, onDeleteSection, translation]);

  const onUpdateSection = useCallback(
    (field, value) => {
      handleDebounce(() => {
        const updateSectionUseCase = getUseCase(
          UPDATE_SECTION_USE_CASE,
          dispatch,
        );
        // TODO: Edit this processing
        const inputValue: UpdateSectionInput = {
          [`${field}`]: value.trim(),
        };
        const updateSectionUseCaseInput: UpdateSectionUseCaseInput = {
          formId: formId.toString(),
          sectionId: sectionSelected.id.toString(),
          input: inputValue,
        };
        updateSectionUseCase.execute(updateSectionUseCaseInput);
      });
    },
    [dispatch, formId, handleDebounce, sectionSelected],
  );

  const score = questions
    ? questions.reduce(
        (a: number, q: Question<QuestionProps>) => a + Number(q.score),
        0,
      )
    : 0;

  const renderSectionDetail = (): ReactNode => {
    const isLoadingSections = !sections;
    if (isLoadingSections) {
      return <Skeleton active />;
    }

    return (
      <SectionDetailWrapper>
        <SectionFactory
          section={selectedSectionDetail}
          score={score}
          isOnlyRemainingSection={sections.length === 1}
          sectionIndex={sectionSelectedIndex}
          onUpdateSection={onUpdateSection}
          onDeleteSection={handleDeleteSectionConfirm}
          disabled={isLoadingGetQuestions}
        />
      </SectionDetailWrapper>
    )
  }

  return <>{renderSectionDetail()}</>; 
};
