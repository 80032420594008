import { styled } from '@xstyled/styled-components';
import { Col, Row } from 'antd';
import { SectionSummaryContainer } from './section-summary';
import { CountDownContainer } from './count-down';
import { pageHeaderHeight, QuizPageHeaderPreviewContainer } from './page-header';
import { QuestionsPreviewContainer } from './questions';
import { SectionPreviewContainer } from './section';
import { appHeaderHeight, styleVariables } from '@lib/common';

export const listColumnWidthInGreaterThan1024pxScreen = 560;
export const listColumnWidthInGreaterThan1280pxScreen = 625;
export const listColumnWidthInGreaterThan1440pxScreen = 720;
export const listColumnWidthInGreaterThan1920pxScreen = 1030;
export const horizontalColumnGutter = 24;
export const verticalColumnGutter = 0;
export const listQuestionColumnSpan = 15;
export const processTrackingColumnSpan = 9;
export const paddingTopOfSectionSummary = 64;
export const marginBetweenItemsSectionSummary = 16;
export const totalMarginTopAndBottom = 16 * 2;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled(Row)`
  flex: 1;

  background-color: ${styleVariables.colors.gray2};
`;

const ListQuestionColumn = styled(Col)`
  height: calc(100vh - ${appHeaderHeight + pageHeaderHeight}px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollableSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0 80px 0;
  height: 100%;

  float: right;

  & > * {
    margin-bottom: 16px;
  }

  @media (min-width: 1024px) {
    width: 560px;
  }

  @media (min-width: 1280px) {
    width: 625px;
  }

  @media (min-width: 1440px) {
    width: 720px;
  }

  @media (min-width: 1920px) {
    width: 1030px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ProcessTrackingColumn = styled(Col)`
  height: calc(100vh - ${appHeaderHeight + pageHeaderHeight}px);
`;

const FixedSection = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 335px;
  padding: ${paddingTopOfSectionSummary}px 0 0;
`;

const SectionSummaryWrapper = styled.div`
  margin: ${marginBetweenItemsSectionSummary}px 0;
  overflow-y: scroll;
  flex: 1;
  border-radius: 16px;
  box-shadow: 0px 3px 6px ${styleVariables.colors.transparentGray6};
  ::-webkit-scrollbar {
    display: none;
  }
`;


const QuizPreviewContainer = () => {
  return (
    <Wrapper>
      <QuizPageHeaderPreviewContainer />

      <Content gutter={[horizontalColumnGutter, verticalColumnGutter]} justify="start" align="top">
        <ListQuestionColumn span={listQuestionColumnSpan}>
          <ScrollableSection>
            <SectionPreviewContainer />
            <QuestionsPreviewContainer />
            {/* <AutoMarginTop>
              <QuizPreviewPagination />
            </AutoMarginTop> */}
          </ScrollableSection>
        </ListQuestionColumn>

        <ProcessTrackingColumn span={processTrackingColumnSpan}>
          <FixedSection>
            <CountDownContainer />
            <SectionSummaryWrapper>
              <SectionSummaryContainer />
            </SectionSummaryWrapper>
          </FixedSection>
        </ProcessTrackingColumn>
      </Content>
    </Wrapper>
  );
};

export default QuizPreviewContainer;