import { MultipleChoiceQuestion } from '@module/form';
import {
  SubmissionMultipleChoiceQuestion,
  SubmissionQuestionLabel,
} from '@module/form-components';

interface MultipleChoiceQuestionPreviewContainerProps {
  question: MultipleChoiceQuestion;
  index: number;
  label: SubmissionQuestionLabel;
}
export const MultipleChoiceQuestionPreviewContainer = (
  props: MultipleChoiceQuestionPreviewContainerProps,
) => {
  const { question, index, label } = props;

  return (
    <SubmissionMultipleChoiceQuestion
      disabled
      label={label}
      question={question.toJsonObject()}
      index={index}
    />
  );
};
