import { PageHeader, Form as FormInput, Space } from 'antd';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EyeOutlined, PlusCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { PrimaryButton, styleVariables, TransparentButton } from '@lib/common';
import {
  createdLocalQuestionStatusSelector,
  createdQuestionStatusSelector,
  QuizEditStatus,
  updateFormInformationStatusSelector,
  updateQuestionStatusSelector,
  updateSectionStatusSelector,
  findFormByIdSelector,
  createAssignmentStatusSelector,
  hasEssayOrFillInBlankQuestionSelector,
  createAssignmentResponseSelector,
} from 'redux/quiz-edit';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { CreateAssignmentModal, renderSuccessModal } from '@module/assignment-components';
import {
  AssignmentType,
  CreatePracticeAssignmentUseCaseInput,
  CREATE_PRACTICE_ASSIGNMENT_USE_CASE,
  GET_ASSIGNMENT_CONFIGURATIONS_USE_CASE,
} from '@module/assignment';
import { FormTab } from '@module/form';
import { getUseCase } from '@lib/plugin-redux-core';
import { assignmentConfigsResponseSelector } from 'redux/assignment';
import { RootReducerState } from 'redux/root.reducer';
import { toLower } from 'lodash';

interface HeaderContainerProps {
  formTitle: string;
  changeActiveTab: (key: FormTab) => void;
  previewMode?: boolean;
}
const PageHeaderWrapper = styled(PageHeader)<{ previewMode?: boolean }>`
  height: 64px;
  background: transparent;
  display: flex;
  padding-right: 48px;
  padding-left: ${(props) => props.previewMode && '8%'};
  .ant-page-header-heading-title {
    font-size: 24px;
  }
  .ant-page-header-heading-left {
    height: min-content;
    align-items: center;
  }
  .ant-page-header-heading {
    flex: 1;
  }
`;

const TagSave = styled.div<{ previewMode?: boolean }>`
  font-size: 12px;
  font-weight: 400;
  color: ${styleVariables.colors.gray10};
  display: ${(props) => (props.previewMode ? 'none' : 'block')};
`;

const FormTitleStyled = styled.p<{ previewMode?: boolean }>`
  margin-bottom: 0;
  height: min-content;
`;
const FlexCenter = styled.div`
  min-height: 32px;
  display: flex;
  align-items: center;
`;
const StyledPrimaryButton = styled(PrimaryButton)`
  padding: 0;
  padding-inline: 16px;
  height: 32px;
  font-size: 14px;
`;

export const HeaderContainer = (props: HeaderContainerProps): ReactElement => {
  // Defin configs
  const { t: translation } = useTranslation('common');
  const router = useRouter();
  const dispatch = useDispatch();
  const { id } = router.query;
  const { formTitle, previewMode, changeActiveTab } = props;
  const { publicRuntimeConfig } = getConfig();
  const { id: formId } = router.query;

  //Define states
  const [visibleModal, setVisibleModal] = useState(false);
  const [newAssignmentInfo, setNewAssignmentInfo] = useState<CreatePracticeAssignmentUseCaseInput>(
    {} as CreatePracticeAssignmentUseCaseInput,
  );

  // Define hooks
  const [createAssignmentForm] = FormInput.useForm();

  //Selectors
  const {
    createdQuestionStatus,
    createdLocalQuestionStatus,
    createAssignmentStatus,
    createAssignmentResponse,
    updateQuestionStatus,
    updateSectionStatus,
    updateFormInformationStatus,
    assignmentConfigsResponse,
    hasEssayOrFillInBlankQuestion,
    formResponse,
  } = useSelector((state: RootReducerState) => ({
    createdQuestionStatus: createdQuestionStatusSelector(state),
    createdLocalQuestionStatus: createdLocalQuestionStatusSelector(state),
    createAssignmentStatus: createAssignmentStatusSelector(state),
    createAssignmentResponse: createAssignmentResponseSelector(state),
    updateQuestionStatus: updateQuestionStatusSelector(state),
    updateSectionStatus: updateSectionStatusSelector(state),
    updateFormInformationStatus: updateFormInformationStatusSelector(state),
    assignmentConfigsResponse: assignmentConfigsResponseSelector(state),
    hasEssayOrFillInBlankQuestion: hasEssayOrFillInBlankQuestionSelector(state),
    formResponse: findFormByIdSelector(state),
  }));

  const onSaveStatus =
    createdLocalQuestionStatus === QuizEditStatus.EXECUTE ||
    createdQuestionStatus === QuizEditStatus.EXECUTE ||
    updateQuestionStatus === QuizEditStatus.EXECUTE ||
    updateSectionStatus === QuizEditStatus.EXECUTE ||
    updateFormInformationStatus === QuizEditStatus.EXECUTE;

  const createPracticeAssignmentUseCase = getUseCase(CREATE_PRACTICE_ASSIGNMENT_USE_CASE, dispatch);

  const getAssignmentConfigurations = useCallback(() => {
    const getAssignmentConfigurationsUseCase = getUseCase(
      GET_ASSIGNMENT_CONFIGURATIONS_USE_CASE,
      dispatch,
    );
    getAssignmentConfigurationsUseCase.execute({});
  }, [dispatch]);

  const handleVisibleModal = useCallback(() => setVisibleModal(!visibleModal), [visibleModal]);
  const onCancelModal = () => setVisibleModal(false);

  const handleOpenPreviewFormTab = useCallback(() => {
    const url = `${publicRuntimeConfig.page.quiz.preview.url}/${id}`;
    window.open(url);
  }, [id, publicRuntimeConfig.page.quiz.preview.url]);

  const handleSubmit = useCallback(
    (values: CreatePracticeAssignmentUseCaseInput) => {
      const createPracticeAssignmentUseCaseInput: CreatePracticeAssignmentUseCaseInput = {
        formInformation: {
          id: formId.toString(),
          version: formResponse.version,
        },
        ...values,
      };
      setNewAssignmentInfo(createPracticeAssignmentUseCaseInput);
      createPracticeAssignmentUseCase.execute(createPracticeAssignmentUseCaseInput);
    },
    [formId, createPracticeAssignmentUseCase, formResponse.version],
  );

  const TitleRender = () => {
    return (
      <>
        <FormTitleStyled previewMode={previewMode}>{formTitle}</FormTitleStyled>
      </>
    );
  };

  const shareAssignmentTranslation = {
    noDescription: translation('noDescription'),
    shareByAssignmentId: translation('shareByAssignmentId'),
    shareAssignmentNow: translation('shareAssignmentNow'),
    close: translation('close'),
    durationAssignment: translation('durationAssignment'),
    copy: translation('copy'),
    copied: translation('copied'),
  };

  useEffect(() => {
    getAssignmentConfigurations();
  }, [getAssignmentConfigurations]);

  useEffect(() => {
    const isCreateAssignmentSuccess = createAssignmentStatus === QuizEditStatus.SUCCESS;
    if (isCreateAssignmentSuccess) {
      createAssignmentForm.resetFields();

      const assignmentType = assignmentConfigsResponse.types.find(
        (assignmentConfig) => assignmentConfig.id === newAssignmentInfo.type,
      );
      const assignmentMode = assignmentConfigsResponse.modes.find(
        (assignmentConfig) => assignmentConfig.id === newAssignmentInfo.mode,
      );
      let assignmentNameTranslated = ``;
      switch (assignmentType.id) {
        case AssignmentType.Practice:
          assignmentNameTranslated = translation('practice');
          break;
        case AssignmentType.Homework:
          break;
        case AssignmentType.Examination:
          break;
      }
      const successTranslationString = `${translation('create')} ${toLower(
        assignmentNameTranslated + ' ' + translation('success'),
      )}`;

      const createAssignmentTranslation = {
        createdAssignmentSuccess: successTranslationString,
      };

      setVisibleModal(false);
      changeActiveTab(FormTab.Assignment);
      renderSuccessModal({
        assignmentInfo: newAssignmentInfo,
        assignmentType,
        assignmentMode,
        idNumber: createAssignmentResponse.idNumber,
        assignmentId: createAssignmentResponse.id.toString(),
        linkShare: publicRuntimeConfig.app.responder.page.assignment.detail.url,
        shareAssignmentTranslation,
        createAssignmentTranslation,
      });
      createPracticeAssignmentUseCase.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAssignmentStatus]);

  const labelsInCreateExamForm = {
    forceLogin: translation('forceLogin'),
    previewResult: translation('previewResult'),
    cheatMode: translation('cheatMode'),
  };
  const labelsInCreateAssignmentForm = {
    cancel: translation('cancel'),
    assignQuiz: translation('assignQuiz'),
    title: translation('exerciseName'),
    type: translation('typeTest'),
    maxNumberOfSubmission: translation('maxNumberDoingTest'),
    deadline: translation('deadline'),
    mode: translation('permission'),
    description: translation('descriptionAdvice'),
    describeIntroduce: translation('describeIntroduce'),
    explanationConfig: translation('explanationConfig'),
    yes: translation('yes'),
    no: translation('no'),
    showScoreAfterSubmit: translation('showScoreAfterSubmit'),
    show: translation('show'),
    notShow: translation('notShow'),
    showExplanationAfterSubmit: translation('showExplanationAfterSubmit'),
    expectationAssignment: translation('expectationAssignment'),
    hasExpiredTime: translation('hasExpiredTime'),
    hasNoExpiredTime: translation('hasNoExpiredTime'),
    startTime: translation('startTime'),
    endTime: translation('endTime'),
    assignmentId: translation('assignmentId'),
    durationAssignment: translation('durationAssignment'),
    minute: translation('minute'),
    hour: translation('hour'),
    day: translation('day'),
    typeTitleMessage: translation('typeTitleMessage'),
    selectDeadlineMessage: translation('selectDeadlineMessage'),
    typeExpectationMessage: translation('typeExpectationMessage'),
    notDisplayScore: translation('notDisplayScore'),
    cannotSetResultExpectation: translation('cannotSetResultExpectation'),
    warningStartAndEndTime: translation('warningStartAndEndTime'),
    ...labelsInCreateExamForm,
  };
  return (
    <>
      <PageHeaderWrapper
        previewMode={previewMode}
        title={<TitleRender />}
        tags={
          <TagSave previewMode={previewMode}>
            {translation(onSaveStatus ? 'Đang lưu thay đổi' : 'Đã lưu thay đổi')}
          </TagSave>
        }
        extra={[
          <FlexCenter key="1">
            {!previewMode && (
              <Space size="middle">
                <TransparentButton
                  size="middle"
                  icon={<EyeOutlined />}
                  onClick={handleOpenPreviewFormTab}
                >
                  {translation('preview')}
                </TransparentButton>
                <StyledPrimaryButton onClick={handleVisibleModal}>
                  <PlusCircleOutlined />
                  {translation('addNewExercise')}
                </StyledPrimaryButton>
              </Space>
            )}
          </FlexCenter>,
        ]}
      />
      <CreateAssignmentModal
        form={createAssignmentForm}
        visibleModal={visibleModal}
        onCancelModal={onCancelModal}
        handleSubmit={handleSubmit}
        isLoadingCreateAssignment={createAssignmentStatus === QuizEditStatus.EXECUTE}
        assignmentConfigsResponse={assignmentConfigsResponse}
        labelsInCreateAssignmentForm={labelsInCreateAssignmentForm}
        hasEssayOrFillInBlankQuestion={hasEssayOrFillInBlankQuestion}
      />
    </>
  );
};
