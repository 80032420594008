import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import { Form as FormInput } from 'antd';
import styled from '@xstyled/styled-components';
import { useForm } from 'antd/lib/form/Form';
import { LoadingPage, PrimaryButton } from '@lib/common';
import {
  Form,
  Grade,
  Subject,
  UpdateFormUseCaseInput,
  GetGradesAndSubjectQueryParams,
  UPDATE_FORM_INFORMATION_USE_CASE,
  GET_GRADES_AND_SUBJECTS_USE_CASE,
  Title,
  GradeIds,
  SubjectIds,
} from '@module/form';
import { getUseCase } from '@lib/plugin-redux-core';
import { useDispatch, useSelector } from 'react-redux';
import {
  EditingQuizInformationComponent,
  ErrorEditingQuizInformationProps,
  LabelEditingQuizInformationProps,
  PlaceHolderEditingQuizInformationProps,
} from '@module/form-components';
import { isFieldEmptyOrUndefined } from '@utils';
import { styleVariables } from '@lib/common';
import { useTranslation } from 'next-i18next';
import {
  QuizEditStatus,
  gradesAndSubjectsSelector,
  updateFormInformationStatusSelector,
} from '../../../redux/quiz-edit';

interface InformationTabProps {
  formModel: Form;
}
interface UpdateFormInformationFormInput {
  title: string;
  gradeIds: string[];
  subjectIds: string[];
  description: string;
}

const Container = styled.div`
  height: 100%;
  padding-top: 32px;
  background-color: ${styleVariables.colors.gray2};
`;
const StyledSaveChangesButton = styled(PrimaryButton)`
  margin-block: 1em;
`;

const StyledInformationFormContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const InformationForm = styled(FormInput)`
  padding: 20px 30px 10px;
  border: 1px solid lightgray;
  border-radius: 6px;
  background-color: ${styleVariables.colors.gray1};

  @media (min-width: 1024px) {
    width: 560px;
  }

  @media (min-width: 1280px) {
    width: 625px;
  }

  @media (min-width: 1440px) {
    width: 720px;
  }

  @media (min-width: 1920px) {
    width: 850px;
  }
`;

const StyledSubmitArea = styled.div`
  display: flex;
  justify-content: right;
`;

export const InformationTabContainer = (
  props: InformationTabProps,
): ReactElement => {
  const { formModel } = props;
  const { t: translation } = useTranslation('common');

  // Define states
  const [isDisableUpdateFormInformationButton, setDisableUpdateFormInformationButtonStatus] =
    useState<boolean>(true);
  const [isGradeDropdownOpen, toggleGradeDropdown] = useState(false);
  const [isSubjectDropdownOpen, toggleSubjectDropdown] = useState(false);

  // Define configs
  const [form] = useForm();
  const dispatch = useDispatch();

  // Get data from Selectors
  const gradesAndSubjects: [Grade[], Subject[]] = useSelector(
    gradesAndSubjectsSelector,
  );
  const updateFormInformationStatus: QuizEditStatus = useSelector(updateFormInformationStatusSelector);

  // Define Use cases
  const getGradesAndSubjectsUseCase = getUseCase(GET_GRADES_AND_SUBJECTS_USE_CASE, dispatch);
  const updateQuizInformationUseCase = getUseCase(
    UPDATE_FORM_INFORMATION_USE_CASE,
    dispatch,
    translation,
  );

  const handleGetGradesAndSubjects = useCallback(() => {
    const getGradesAndSubjectsQueryParams: GetGradesAndSubjectQueryParams = {};

    getGradesAndSubjectsUseCase.execute(getGradesAndSubjectsQueryParams);
  }, [getGradesAndSubjectsUseCase]);

  useEffect(() => {
    if (!gradesAndSubjects) {
      handleGetGradesAndSubjects();
    }
  }, [gradesAndSubjects, handleGetGradesAndSubjects]);

  useEffect(() => {
    const isUpdateFormInformationSuccess =
      updateFormInformationStatus === QuizEditStatus.SUCCESS;
    if (isUpdateFormInformationSuccess) {
      setDisableUpdateFormInformationButtonStatus(true);
    }
  }, [updateFormInformationStatus]);

  const handleUpdateFormInformation = () => {
    const { title, description, subjectIds, gradeIds }: UpdateFormInformationFormInput =
      form.getFieldsValue();

    const updateFormUseCaseInput: UpdateFormUseCaseInput = {
      id: formModel.id.toString(),
      input: {
        title: title.trim(),
        subjectIds,
        gradeIds,
        description: description.trim(),
      },
    };
    updateQuizInformationUseCase.execute(updateFormUseCaseInput);
  };

  const handleUpdateFormValuesChange = (changedValue: any, values: any) => {
    const { title, gradeIds, subjectIds } = form.getFieldsValue();
    const isGradeIdsNotValid = gradeIds && !GradeIds.isValid(gradeIds);

    const isNotEnoughConditionToUpdateForm =
      isFieldEmptyOrUndefined(title) ||
      !Title.isValid(title) ||
      isGradeIdsNotValid ||
      !SubjectIds.isValid(subjectIds);

    if (isNotEnoughConditionToUpdateForm) {
      setDisableUpdateFormInformationButtonStatus(true);
      return;
    }

    setDisableUpdateFormInformationButtonStatus(false);
  };
  const label: LabelEditingQuizInformationProps = {
    title: translation('title'),
    grade: translation('grade'),
    subjects: translation('subjects'),
    description: translation('description'),
  };

  const error: ErrorEditingQuizInformationProps = {
    title: translation('titleEmptyError'),
    grade: translation('gradeEmptyError'),
    subjects: translation('subjectsEmptyError'),
  };

  const placeHolder: PlaceHolderEditingQuizInformationProps = {
    title: translation('formTitlePlaceholder'),
    grade: translation('gradePlaceholder'),
    subjects: translation('subjectPlaceholder'),
    description: translation('descriptionPlaceholder'),
  };

  const onCloseGradeDropdown = useCallback(() => {
    toggleGradeDropdown(false);
  }, [toggleGradeDropdown]);

  const onCloseSubjectDropdown = useCallback(() => {
    toggleSubjectDropdown(false);
  }, [toggleSubjectDropdown]);

  const onToggleGradeDropdown = useCallback(() => {
    toggleGradeDropdown((prevState) => !prevState);
  }, [toggleGradeDropdown]);

  const onToggleSubjectDropdown = useCallback(() => {
    toggleSubjectDropdown((prevState) => !prevState);
  }, [toggleSubjectDropdown]);


  const renderUpdateFormInformation = (): ReactNode => {
    if (!gradesAndSubjects) {
      return <LoadingPage />;
    }

    const [grades, subjects] = gradesAndSubjects;
    return (
      <Container>
        <StyledInformationFormContainer>
          <InformationForm
            form={form}
            layout="vertical"
            onValuesChange={handleUpdateFormValuesChange}
            initialValues={{
              ...formModel.toJsonObject(),
              gradeIds: formModel.grades.map((grade) => grade.id.toString()),
              subjectIds: formModel.subjects.map((subject) => subject.id.toString()),
            }}
          >
            <EditingQuizInformationComponent
              subjects={subjects}
              grades={grades}
              label={label}
              error={error}
              placeHolder={placeHolder}
              onCloseGradeDropdown={onCloseGradeDropdown}
              onToggleGradeDropdown={onToggleGradeDropdown}
              isGradeDropdownOpen={isGradeDropdownOpen}
              onCloseSubjectDropdown={onCloseSubjectDropdown}
              onToggleSubjectDropdown={onToggleSubjectDropdown}
              isSubjectDropdownOpen={isSubjectDropdownOpen}
              translation={translation}
            />
            <StyledSubmitArea>
              <StyledSaveChangesButton
                disabled={isDisableUpdateFormInformationButton}
                onClick={handleUpdateFormInformation}
                loading={updateFormInformationStatus === QuizEditStatus.EXECUTE}
              >
                {translation('saveChange')}
              </StyledSaveChangesButton>
            </StyledSubmitArea>
          </InformationForm>
        </StyledInformationFormContainer>
      </Container>
    );
  }

  return <>{renderUpdateFormInformation()}</>;
};
