import { Row, Col } from 'antd';
import { ReactElement } from 'react';
import styled from '@xstyled/styled-components';
import { styleVariables } from '@lib/common';
import { SectionDetailContainer } from './sections';
import { CreateQuestionOrSectionContainer } from './create-question-or-section';
import { SectionSummaryContainer } from './section-summary';
import { QuizQuestionsContainer } from './questions/quiz-questions.container';

const SectionSummaryCol = styled(Col)`
  border-right: 1px solid ${styleVariables.colors.gray7};
  height: inherit;
`;
const FormQuestionTabCol = styled(Col)`
  background: ${styleVariables.colors.gray2};
  height: inherit;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: ${styleVariables.colors.gray9};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${styleVariables.colors.gray10};
    &:hover {
      background: ${styleVariables.colors.gray11};
    }
  }
`;
const FormQuestionLayout = styled.div`
  width: 80%;
  padding: 32px 32px 50px 100px;
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 0;
  }
`;

const QuestionsRow = styled(Row)`
  height: 100%;
`;

const TabEditingQuestionsRow = styled(Row)`
  background: ${styleVariables.colors.gray2};
  height: inherit;
  width: inherit;
`;

export const EditingQuestionsTabContainer = (): ReactElement => {
  return (
    <TabEditingQuestionsRow wrap={false}>
      <SectionSummaryCol span={5}>
        <SectionSummaryContainer />
      </SectionSummaryCol>
      <FormQuestionTabCol flex="auto">
        <FormQuestionLayout>
          <SectionDetailContainer />
          <QuestionsRow>
            <Col span={24}>
              <QuizQuestionsContainer />
            </Col>
          </QuestionsRow>
        </FormQuestionLayout>
        <CreateQuestionOrSectionContainer />
      </FormQuestionTabCol>
    </TabEditingQuestionsRow>
  );
};
