import { getUseCase } from '@lib/plugin-redux-core';
import {
  Form,
  GetSectionSummaryInput,
  GET_SECTION_SUMMARIES_USE_CASE,
  SectionSummary,
} from '@module/form';
import { useDispatch, useSelector } from 'react-redux';
import {
  formSelector,
  sectionSummariesSelector,
  selectedSectionIndexSelector,
} from '../../../redux/quiz-preview';
import { UPDATE_SELECTED_SECTION } from 'redux/root.action';
import { useTranslation } from 'next-i18next';
import { SubmissionSectionSummary, SubmissionSectionSummaryLabel } from '@module/form-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@xstyled/styled-components';
import { styleVariables } from '@lib/common';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 24px;

  background-color: ${styleVariables.colors.gray1};
`;

export const SectionSummaryContainer = () => {
  const { t: translation } = useTranslation('common');
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState([1]);
  const label: SubmissionSectionSummaryLabel = useMemo(() => {
    return {
      answered: translation('answered'),
      submit: translation('submit'),
      section: translation('section'),
    };
  }, [translation]);

  const form: Form = useSelector(formSelector);
  const selectedSectionIndex: number = useSelector(selectedSectionIndexSelector);
  const sectionSummaries: SectionSummary[] = useSelector(sectionSummariesSelector);

  const executeFindSectionSummariesUseCase = useCallback(() => {
    const findSectionSummariesInput: GetSectionSummaryInput = {
      formId: form.id.toString(),
    };
    const getSectionSummariesUseCase = getUseCase(GET_SECTION_SUMMARIES_USE_CASE, dispatch);

    getSectionSummariesUseCase.execute(findSectionSummariesInput);
  }, [dispatch, form.id]);

  useEffect(() => {
    executeFindSectionSummariesUseCase();
  }, [executeFindSectionSummariesUseCase]);

  const onSelectSection = useCallback(
    (keys: string[]) => {
      const isExistedSelectedSection = keys.length > 0;
      if (isExistedSelectedSection) {
        const sectionIndex = parseInt(keys[keys.length - 1]) - 1;
        setActiveKey([sectionIndex + 1]);

        const isSelectDifferentSection = sectionIndex !== selectedSectionIndex;
        if (isSelectDifferentSection) {
          dispatch({
            type: UPDATE_SELECTED_SECTION,
            payload: {
              selectedSection: form.sections[sectionIndex],
              selectedSectionIndex: sectionIndex,
            },
          });
        }
      }
    },
    [dispatch, form.sections, selectedSectionIndex],
  );

  return (
    <Wrapper>
      <SubmissionSectionSummary
        sectionSummaries={sectionSummaries}
        onSelectSection={onSelectSection}
        label={label}
        readonly
      />
    </Wrapper>
  );
};
