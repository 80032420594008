import { styleVariables } from '@lib/common';
import { CountDownTimer, CountDownTimerLabel } from '@module/form-components';
import { styled } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

const Wrapper = styled.div`
  padding: 16px;
  width: 100%;

  background: ${styleVariables.colors.gray1};

  border-radius: 16px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);

  user-select: none;
`;

export const CountDownContainer = () => {
  // Define configs
  const { t: translation } = useTranslation('common');
  const label: CountDownTimerLabel = useMemo(() => {
    return {
      time: translation('time'),
      day: translation('day'),
      hour: translation('hour'),
      minute: translation('minute'),
      second: translation('second'),
      submit: translation('submit'),
    };
  }, [translation]);

  return (
    <Wrapper>
      <CountDownTimer label={label} disabled />
    </Wrapper>
  );
};
