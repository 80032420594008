import { QuestionType } from '@module/form';
export * from './single-choice-question-edit.component';
export * from './multiple-choice-question-edit.component';
export * from './essay-question-edit.component';
export * from './essay-question-preview.component';
export * from './single-choice-question-preview.component';
export * from './multiple-choice-question-preview.component';
export * from './fill-in-blank-question-edit.component';
export * from './fill-in-blank-question-preview.component';
export var questionTypeOptions = [{
  label: 'Trắc nghiệm',
  value: QuestionType.SingleChoice
}, {
  label: 'Tự luận',
  value: QuestionType.Essay
}, {
  label: 'Chọn nhiều đáp án',
  value: QuestionType.MultipleChoice
}, {
  label: 'Điền vào chỗ trống',
  value: QuestionType.FillInBlank
}];