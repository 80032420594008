import React, {
  ReactElement,
  useEffect,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { AppLayout, MenuItemProps, showModalConfirm, styleVariables } from '@lib/common';
import { ExclamationCircleOutlined, LogoutOutlined } from '@ant-design/icons';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { getUseCase } from '@lib/plugin-redux-core';
import { SIDEBAR_USE_CASE } from '../../redux/root.action';
import getConfig from 'next/config';
import Link from 'next/link';
import { DEFAULT_PATH, MenuItemKey, routesMap } from './menu-items';
import { getProfileFromLocalStorage } from '../../utils';
import { sidebarStateSelector } from '../../redux/sidebar';
import { AuthProvider } from '../../contexts/authen';
import { GET_USER_BY_ID_USE_CASE, SIGN_OUT_USE_CASE, User } from '@module/user';
import styled from 'styled-components';
import { Image, Modal } from 'antd';
import {
  userSelector,
  signOutStatusSelector,
  PageLayoutStatus,
  getUserByIdStatusSelector,
} from '../../redux/layout';

interface TestMakerLayoutProps {
  children: ReactNode;
  previewMode?: boolean;
  withoutSidebar?: boolean;
}

const StyledImage = styled.div`
  .ant-image {
    height: 0px;
  }
`;

const ModalTitle = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAlertIcon = styled(ExclamationCircleOutlined)`
  margin-right: 10px;

  color: ${styleVariables.colors.blue3};

  font-size: 25px;
`;

export const TestMakerLayout = (props: TestMakerLayoutProps): ReactElement => {
  // Define states
  const [isModalAlertVisible, setModalAlertVisibility] = useState<boolean>(false);


  // Get props data
  const { children, previewMode, withoutSidebar } = props;

  // Define configs
  const { t } = useTranslation('common');
  const router = useRouter();
  const { pathname } = router;
  const dispatch = useDispatch();
  const { publicRuntimeConfig } = getConfig();

  // Get data from selector
  const userResponse: User = useSelector(userSelector);
  const getUserByIdStatus: number = useSelector(getUserByIdStatusSelector);
  const signOutStatus: number = useSelector(signOutStatusSelector);
  const isSidebarCollapsed: boolean = useSelector(sidebarStateSelector);

  // Get data from local storage
  const { id: userId } = getProfileFromLocalStorage();

  // Define default value
  const currentPath = useMemo(() => {
    if (pathname === '/') {
      return DEFAULT_PATH;
    }
    const [, navPath] = pathname.split('/');
    return `/${navPath}`;
  }, [pathname]);

  useEffect(() => {
    const isSignOutSuccess = signOutStatus === PageLayoutStatus.SUCCESS;
    if (isSignOutSuccess) {
      router.push(publicRuntimeConfig.app.authentication.page.signIn.url);
    }
  }, [
    publicRuntimeConfig.app.authentication.page.signIn.url,
    router,
    signOutStatus,
  ]);

  // Get User.
  const handleGetUserById = useCallback(() => {
    const getUserByIdUseCase = getUseCase(GET_USER_BY_ID_USE_CASE, dispatch);

    getUserByIdUseCase.execute(userId);
  }, [dispatch, userId]);

  useEffect(() => {
    handleGetUserById();
  }, [handleGetUserById]);

  // ------ Call sidebar to collapse  --------
  const sideBarUseCase = getUseCase(SIDEBAR_USE_CASE, dispatch);

  const collapseSidebar = (toggle: boolean) => {
    sideBarUseCase.execute({ isSidebarCollapsed: toggle });
  };

  const handleSignOutSubmit = useCallback(() => {
    const signOutUseCase = getUseCase(SIGN_OUT_USE_CASE, dispatch);

    signOutUseCase.execute(null);

    setModalAlertVisibility(true);
  }, [dispatch]);

  const handleSignOutConfirm = useCallback(() => {
    showModalConfirm({
      title: t('signOut'),
      icon: <ExclamationCircleOutlined />,
      content: t('areYouSureYouWantToSignOut'),
      okText: t('confirm'),
      cancelText: t('cancel'),
      onOk: handleSignOutSubmit,
    });
  }, [handleSignOutSubmit, t]);

  const menuItems: MenuItemProps[] = useMemo(
    () => [
      {
        key: MenuItemKey.Space,
        icon: (
          <StyledImage>
            <Image
              src="/icons/Folders.png"
              preview={false}
              alt="class"
              height={18}
            />
          </StyledImage>
        ),
        path: routesMap.space,
        title: 'form',
      },
      {
        key: MenuItemKey.Assignment,
        icon: (
          <StyledImage>
            <Image
              src="/icons/ListBullets.png"
              preview={false}
              alt="class"
              height={18}
            />
          </StyledImage>
        ),
        path: routesMap.assignment,
        title: 'assignment',
      },
    ],
    [],
  );

  const bottomMenuItems = useMemo(
    () => [
      {
        key: MenuItemKey.SignOut,
        icon: <LogoutOutlined />,
        title: 'signOut',
        onClick: handleSignOutConfirm,
      },
    ],
    [handleSignOutConfirm],
  );

  const getCurrentKey = useCallback(() => {
    switch (currentPath) {
      case routesMap.space:
      case routesMap.folder:
        return MenuItemKey.Space;
      case routesMap.assignment:
        return MenuItemKey.Assignment;
      case routesMap.test:
        return MenuItemKey.Test;
      case routesMap.help:
        return MenuItemKey.Help;
      case routesMap.setting:
        return MenuItemKey.Setting;
    }
  }, [currentPath]);

  const logoSrc = previewMode
    ? '/images/app-logo-teacher-primary-white.png'
    : '/images/app-logo-primary.svg';
  const navigateToPath = (path: string, title: ReactNode | string) => (
    <Link href={path}>
      <a>{typeof title === 'string' ? t(title) : title}</a>
    </Link>
  );
  const headerTag = {
    content: t('testMaker'),
  };

  const userInformation = useMemo(() => {
    return userResponse && userResponse.firstName && userResponse.lastName
      ? `${userResponse.firstName} ${userResponse.lastName}`
      : t('unknownUser');
  }, [t, userResponse]);

  return (
    <>
      <Modal
        open={isModalAlertVisible}
        title={
          <ModalTitle>
            <StyledAlertIcon />
            <span>{t('alert')}</span>
          </ModalTitle>
        }
        closable={false}
        centered={true}
        footer={null}
      >
        {t('youWillBeRedirectToSignInPageNow')}
      </Modal>

      <AuthProvider>
        <AppLayout
          menuItems={menuItems}
          urlNavigateAccountSettingPage={
            publicRuntimeConfig.app.authentication.page.accountSetting.updateProfile.url
          }
          bottomMenuItems={bottomMenuItems}
          translation={t}
          selectedKeys={[getCurrentKey()]}
          logoNavigation={publicRuntimeConfig.page.home.url}
          logoSrc={logoSrc}
          isLoadingUserInfo={getUserByIdStatus === PageLayoutStatus.EXECUTE}
          userInfo={userInformation}
          isSidebarCollapsed={isSidebarCollapsed}
          previewMode={previewMode}
          withoutSidebar={withoutSidebar}
          navigateToPath={navigateToPath}
          collapseSidebar={collapseSidebar}
          headerTag={headerTag}
        >
          {children}
        </AppLayout>
      </AuthProvider>
    </>
  );
};
