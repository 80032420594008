import {
  CorrectAnswerProps,
  CreateQuestionUseCaseInput,
  CREATE_QUESTION_USE_CASE,
  DELETE_QUESTION_USE_CASE,
  EssayQuestion,
  FillInBlankQuestion,
  FindQuestionsUseCaseQueryParams,
  FIND_ALL_QUESTIONS_USE_CASE,
  Question,
  QuestionProps,
  QuestionType,
  ResponseType,
  SingleChoiceQuestion,
  UPDATE_QUESTION_TYPE_USE_CASE,
} from '@module/form';
import { getUseCase } from '@lib/plugin-redux-core';
import { ReactNode, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { MultipleChoiceQuestion } from '@module/form';

import { Skeleton } from 'antd';
import { SingleChoiceContainer } from './single-choice.container';
import { MultipleChoiceContainer } from './multiple-questions.container';
import {
  createdLocalQuestionStatusSelector,
  isLoadingGetQuestionsSelector,
  questionListSelector,
  QuizEditStatus,
  sectionSelectedSelector,
} from 'redux/quiz-edit';
import { EssayQuestionContainer } from './essay-questions.container';
import { FillInBlankQuestionContainer } from './fill-in-blank.container';
import { QUESTION_FOCUS_USE_CASE } from 'redux/root.action';
import { useDebounce } from '@lib/common';
import { UniqueEntityID } from '@smart-form/domains';

const QuestionWrapper = styled.div`
  padding-bottom: 16px;
`;

export const QuizQuestionsContainer = () => {
  const handleDebounce = useDebounce();

  const sectionSelected = useSelector(sectionSelectedSelector);
  const isLoading = useSelector(isLoadingGetQuestionsSelector);
  const questions: Question<QuestionProps>[] = useSelector(questionListSelector);
  const createLocalQuestionStatus = useSelector(createdLocalQuestionStatusSelector);

  const dispatch = useDispatch();

  const handleGetQuestions = useCallback(() => {
    if (sectionSelected) {
      const getQuestionsUseCase = getUseCase(FIND_ALL_QUESTIONS_USE_CASE, dispatch);
      const getQuestionsUseCaseInput: FindQuestionsUseCaseQueryParams = {
        sectionId: sectionSelected.id.toString(),
        type: ResponseType.All,
      };
      getQuestionsUseCase.execute(getQuestionsUseCaseInput);
    }
  }, [dispatch, sectionSelected]);

  const createQuestionUseCase = getUseCase(CREATE_QUESTION_USE_CASE, dispatch);

  const resetCreateQuestion = useCallback(() => {
    createQuestionUseCase.reset();
  }, [createQuestionUseCase]);

  const onUpdateTypeQuestion = useCallback(
    (questionId, value) => {
      if ((!questionId && !value) || !sectionSelected) return;

      const updateQuestionTypeUseCase = getUseCase(UPDATE_QUESTION_TYPE_USE_CASE, dispatch);
      const question = questions.find((q) => q.id.toString() === questionId);

      const isSameQuestionType = question.type === value;
      if (isSameQuestionType) {
        return;
      }

      const useCaseInput: CreateQuestionUseCaseInput = {
        id: questionId.toString(),
        type: value,
        sectionId: question.sectionId,
        formId: question.formId,
        description: question.description,
      };

      const input = getQuestionAnswers(question, value, useCaseInput);
      updateQuestionTypeUseCase.execute(input);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, questions, sectionSelected],
  );

  const getQuestionAnswers = (
    question: Question<QuestionProps>,
    newQuestionType: QuestionType,
    useCaseInput: CreateQuestionUseCaseInput,
  ) => {
    const isCurrentQuestionIsEssayQuestion = question.isEssayQuestion();
    if (isCurrentQuestionIsEssayQuestion) {
      return createDefaultQuestionInput(useCaseInput);
    }

    switch (newQuestionType) {
      case QuestionType.Essay: {
        return {
          ...useCaseInput,
        };
      }
      case QuestionType.SingleChoice: {
        const { answers, correctAnswers } = question as
          | MultipleChoiceQuestion
          | FillInBlankQuestion;

        const singleChoiceInput: CreateQuestionUseCaseInput = {
          ...useCaseInput,
          answers: answers.map((a) => ({
            id: a.id.toString(),
            description: a.description,
          })),
          correctAnswers: [
            {
              id: correctAnswers[0].id.toString(),
              answerId: correctAnswers[0].answerId,
            },
          ],
        };
        return singleChoiceInput;
      }
      case QuestionType.MultipleChoice: {
        const { answers, correctAnswers } = question as SingleChoiceQuestion | FillInBlankQuestion;

        const multipleChoiceInput: CreateQuestionUseCaseInput = {
          ...useCaseInput,
          answers: answers.map((a) => ({
            id: a.id.toString(),
            description: a.description,
          })),
          correctAnswers: correctAnswers.map((c) => ({
            id: c.id.toString(),
            answerId: c.answerId,
          })),
        };
        return multipleChoiceInput;
      }
      case QuestionType.FillInBlank: {
        const { answers, correctAnswers } = question as
          | SingleChoiceQuestion
          | MultipleChoiceQuestion;

        const fillInBlankCorrectAnswers: CorrectAnswerProps[] = correctAnswers.map((c) => ({
          id: c.id.toString(),
          answerId: c.answerId.toString(),
        }));

        const isAnswersAndCorrectAnswersNotTheSameLength = answers.length > correctAnswers.length;
        if (isAnswersAndCorrectAnswersNotTheSameLength) {
          // eslint-disable-next-line no-loops/no-loops
          for (let index = correctAnswers.length; index < answers.length; ++index) {
            const generatedCorrectAnswer: CorrectAnswerProps = {
              id: new UniqueEntityID().toString(),
              answerId: answers[index].id.toString(),
            };
            fillInBlankCorrectAnswers.push(generatedCorrectAnswer);
          }
        }

        const fillInBlankInput: CreateQuestionUseCaseInput = {
          ...useCaseInput,
          answers: answers.map((a) => ({
            id: a.id.toString(),
            description: a.description,
          })),
          correctAnswers: fillInBlankCorrectAnswers,
        };
        return fillInBlankInput;
      }
    }
  };

  const createDefaultQuestionInput = (
    useCaseInput: CreateQuestionUseCaseInput,
  ): CreateQuestionUseCaseInput => {
    return {
      ...useCaseInput,
    };
  };

  const onFocusQuestion = useCallback(
    (index: number) => {
      dispatch({
        type: QUESTION_FOCUS_USE_CASE,
        payload: index,
      });
    },
    [dispatch],
  );

  useEffect(() => {
    handleDebounce(() => {
      handleGetQuestions();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleGetQuestions, sectionSelected]);

  const onDeleteQuestion = useCallback(
    (question) => {
      const deleteQuestionUseCase = getUseCase(DELETE_QUESTION_USE_CASE, dispatch);
      const questionId = question.id.toString();
      deleteQuestionUseCase.execute(questionId);
    },
    [dispatch],
  );

  useEffect(() => {
    if (!createLocalQuestionStatus || createLocalQuestionStatus !== QuizEditStatus.SUCCESS) return;
    const newQuestionIndex = questions.length - 1;
    const questionPreviewElement = document.getElementById(`qp_${newQuestionIndex}`);
    const questionEditElement = document.getElementById(`qe_${newQuestionIndex}`);
    const questionFocusUseCase = getUseCase(QUESTION_FOCUS_USE_CASE, dispatch);
    if (questionPreviewElement) {
      const mouseDownEvent = new MouseEvent('mousedown', {
        view: window,
        bubbles: true,
        cancelable: true,
      });

      questionPreviewElement.dispatchEvent(mouseDownEvent);
      questionFocusUseCase.execute(newQuestionIndex);
      const scrollTimeout = setTimeout(() => {
        questionEditElement.scrollIntoView({
          behavior: 'smooth',
          block: "nearest"
        });
        resetCreateQuestion();
      }, 0);
      return () => clearTimeout(scrollTimeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetCreateQuestion, dispatch, questions]);

  const renderQuestionViewFactory = (question: Question<QuestionProps>, index: number) => {
    switch (question.type) {
      case QuestionType.Essay:
        return (
          <EssayQuestionContainer
            question={question as EssayQuestion}
            index={index}
            onUpdateType={onUpdateTypeQuestion}
            onDelete={() => onDeleteQuestion(question)}
            onFocusQuestion={onFocusQuestion}
          />
        );

      case QuestionType.SingleChoice:
        return (
          <SingleChoiceContainer
            question={question as SingleChoiceQuestion}
            index={index}
            onUpdateType={onUpdateTypeQuestion}
            onDelete={() => onDeleteQuestion(question)}
            onFocusQuestion={onFocusQuestion}
          />
        );
      case QuestionType.MultipleChoice:
        return (
          <MultipleChoiceContainer
            question={question as MultipleChoiceQuestion}
            index={index}
            onUpdateType={onUpdateTypeQuestion}
            onDelete={() => onDeleteQuestion(question)}
            onFocusQuestion={onFocusQuestion}
          />
        );
      case QuestionType.FillInBlank: {
        return (
          <FillInBlankQuestionContainer
            question={question as FillInBlankQuestion}
            index={index}
            onUpdateTypeQuestion={onUpdateTypeQuestion}
            onDeleteQuestion={() => onDeleteQuestion(question)}
            onFocusQuestion={onFocusQuestion}
          />
        );
      }

      default:
        break;
    }
  };

  const renderQuestions = (): ReactNode => {
    if (isLoading || !questions) {
      return <Skeleton active paragraph={{ rows: 6 }} />;
    }

    return (
      <>
        {questions.map((question: Question<QuestionProps>, index: number) => {
          return (
            <QuestionWrapper key={question.id.toString()}>
              {renderQuestionViewFactory(question, index)}
            </QuestionWrapper>
          );
        })}
      </>
    )
  }



  return <>{renderQuestions()}</>;
};
