import { getUseCase } from '@lib/plugin-redux-core';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useState, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TableAssignments,
  ShareContent,
  ActionBarAssignment,
  EmptyAssignmentPlaceholder,
  EmptyAssignmentPlaceholderLabel,
} from '@module/assignment-components';
import { styleVariables } from '@lib/common';
import { GET_ASSIGNMENTS_USE_CASE } from '@module/assignment';
import { useRouter } from 'next/router';
import { heights } from '../../../utils';
import styled from '@xstyled/styled-components';
import { Avatar, Col, Modal, Row, Skeleton } from 'antd';
import getConfig from 'next/config';
import { useDebounce } from '@lib/common';
import {
  createAssignmentStatusSelector,
  assignmentConfigsResponseSelector,
  assignmentResponseSelector,
  loadingGetAssignmentsSelector,
  QuizEditStatus,
} from '../../../redux/quiz-edit';
import { RootReducerState } from 'redux/root.reducer';

interface AssignmentQuery {
  formId: string;
  search: string;
  type: string;
  mode: string;
  status: string;
}
const initialFilterQuery: Partial<AssignmentQuery> = {
  type: '',
  mode: '',
  status: '',
};

const StyledListAssignmentContainer = styled.div`
  height: calc(100vh - ${heights.header} - ${heights.pageTitle} - ${heights.tabTitle});
  padding: 0px 45px 30px 45px;
  background: ${styleVariables.colors.gray2};

  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
`;

const StyledActionBarAssignment = styled(Row)`
  width: 100%;
  background-color: ${styleVariables.colors.gray2};
  padding-top: 52px;
  padding-bottom: 24px;
  position: sticky;
  z-index: 10;
  top: 0;
  left: 0;
`;

const AssignmentsTableRow = styled(Row)`
  height: 100%;
`;

const AssignmentsTable = styled(Col)`
  height: 100%;
`;

const StyledTable = styled.div`
  justify-content: center;
  height: 85%;
  overflow: hidden scroll;
  .ant-table-wrapper {
    margin-bottom: 20px;
    .ant-table-thead > tr > th {
      background: ${styleVariables.colors.gray3};
    }
    .ant-table-thead {
      position: sticky;
      z-index: 10;
      top: 0;
      left: 0;
    }
  }
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${styleVariables.colors.gray9};
    border-radius: 10px;
  }
}
`;
const StyledTitleSuccess = styled.span`
  font-weight: 600;
  font-size: 20px;
  margin-left: 11px;
  align-items: center;
`;

const EmptyPlaceholderArea = styled.div`
  height: 100%;
`;

export const ListAssignmentByFormIdContainer = () => {
  // Define configs
  const router = useRouter();
  const { publicRuntimeConfig } = getConfig();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const handleDebounce = useDebounce(500);
  const { id: formId } = router.query;

  // Define states
  const [assignmentQuery, setAssignmentQuery] = useState<Partial<AssignmentQuery>>({
    formId: formId ? formId.toString() : null,
    search: '',
    ...initialFilterQuery,
  });

  // Define configs
  const emptyAssignmentPlaceholderLabel: EmptyAssignmentPlaceholderLabel = {
    title: t('emptyAssignment'),
    description: t('toStartNewAssignment'),
  };

  const { assignmentResponse, assignmentConfigsResponse, createAssignmentStatus, isLoadingList } =
    useSelector((state: RootReducerState) => ({
      assignmentResponse: assignmentResponseSelector(state),
      assignmentConfigsResponse: assignmentConfigsResponseSelector(state),
      createAssignmentStatus: createAssignmentStatusSelector(state),
      isLoadingList: loadingGetAssignmentsSelector(state),
    }));

  const handleSearch = (search: string) => {
    handleDebounce(() => setAssignmentQuery({ ...assignmentQuery, search }));
  };

  const getAssignmentList = useCallback(() => {
    const getAssignmentUseCase = getUseCase(GET_ASSIGNMENTS_USE_CASE, dispatch);
    getAssignmentUseCase.execute(assignmentQuery);
  }, [dispatch, assignmentQuery]);

  useEffect(() => {
    getAssignmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentQuery]);

  useEffect(() => {
    if (createAssignmentStatus === QuizEditStatus.SUCCESS) {
      getAssignmentList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAssignmentStatus]);

  const shareAssignmentTranslation = {
    noDescription: t('noDescription'),
    shareByAssignmentId: t('shareByAssignmentId'),
    shareAssignmentNow: t('shareAssignmentNow'),
    close: t('close'),
    durationAssignment: t('durationAssignment'),
    copy: t('copy'),
    copied: t('copied'),
  };

  const handleReload = useCallback(() => {
    getAssignmentList();
  }, [getAssignmentList]);
  const handleFilter = ({ type, mode, status }) => {
    setAssignmentQuery({ ...assignmentQuery, type, mode, status });
  };
  const handleClearFilter = () => {
    setAssignmentQuery({ ...assignmentQuery, ...initialFilterQuery });
  };
  const handleShareAssignment = (id: string, title: string, idNumber: string) => {
    Modal.info({
      icon: (
        <>
          <Avatar src="/images/icons/icon-check-circle.png" size="large" />
          <StyledTitleSuccess>Chia sẻ bài {title}</StyledTitleSuccess>
        </>
      ),
      content: (
        <ShareContent
          assignmentId={id}
          idNumber={idNumber}
          linkShare={publicRuntimeConfig.app.responder.page.assignment.detail.url}
          shareAssignmentTranslation={shareAssignmentTranslation}
        />
      ),
      className: 'share-modal',
    });
  };

  const navigateToAssignmentDetail = (selectedAssignmentId: string) => {
    const { url: quizUrl } = publicRuntimeConfig.page.quiz;
    const { url: assignmentUrl } = publicRuntimeConfig.page.assignment;
    const url = `${quizUrl}/${formId}${assignmentUrl}/${selectedAssignmentId}`;
    return router.push(url);
  };

  const tableAssignmentTranslation = {
    idNumber: t('idNumber'),
    share: t('share'),
    delete: t('delete'),
    creationDate: t('creationDate'),
    title: t('assignmentTitle'),
    status: t('status'),
    typeTest: t('typeTest'),
    startEndDate: t('startEndDate'),
    sharingMode: t('sharingMode'),
    totalAttendee: t('totalAttendee'),
    durationAssignment: t('durationAssignment'),
    minute: t('minute'),
    previewDetail: t('previewDetail'),
    clickToViewDetail: t('clickToViewDetail'),
  };

  const renderAssignmentByFormIdContent = (): ReactNode => {
    if (isLoadingList || !assignmentResponse) {
      return <Skeleton active paragraph={{ rows: 6 }} />;
    }

    const isEmptyAssignments = assignmentResponse.length === 0;
    if (isEmptyAssignments) {
      return (
        <EmptyPlaceholderArea>
          <EmptyAssignmentPlaceholder label={emptyAssignmentPlaceholderLabel} />
        </EmptyPlaceholderArea>
      );
    }

    return (
      <StyledTable>
        <TableAssignments
          dataSources={assignmentResponse}
          navigateToAssignmentDetail={navigateToAssignmentDetail}
          onShareAssignment={handleShareAssignment}
          tableAssignmentTranslation={tableAssignmentTranslation}
        />
      </StyledTable>
    );
  };

  return (
    <StyledListAssignmentContainer>
      <StyledActionBarAssignment>
        <Col span={24}>
          <ActionBarAssignment
            handleSearch={handleSearch}
            isLoadingList={isLoadingList}
            handleReload={handleReload}
            handleFilter={handleFilter}
            handleClearFilter={handleClearFilter}
            assignmentConfigs={assignmentConfigsResponse}
            translation={t}
          />
        </Col>
      </StyledActionBarAssignment>
      <AssignmentsTableRow>
        <AssignmentsTable span={24}>{renderAssignmentByFormIdContent()}</AssignmentsTable>
      </AssignmentsTableRow>
    </StyledListAssignmentContainer>
  );
};

export default ListAssignmentByFormIdContainer;
