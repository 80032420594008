import { showModalConfirm, useDebounce } from '@lib/common';
import {
  CreateSingleMultipleChoiceAnswerUseCaseInput,
  CREATE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
  DeleteSingleMultipleChoiceAnswerUseCaseInput,
  DELETE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
  UpdateQuestionUseCaseInput,
  UpdateSingleMultipleChoiceAnswerUseCaseInput,
  UpdateSingleMultipleChoiceCorrectAnswerUseCaseInput,
  UPDATE_QUESTION_USE_CASE,
  UPDATE_SINGLE_MULTIPLE_CHOICE_CORRECT_ANSWER_USE_CASE,
} from '@module/form';
import { MultipleChoice } from '@module/form-components';
import { getUseCase } from '@lib/plugin-redux-core';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MultipleChoiceQuestion } from '@module/form';
import { UPDATE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE } from '@module/form';
import {
  createAnswerChoiceQuestionStatusSelector,
  deleteAnswerChoiceQuestionStatusSelector,
  sectionSelectedSelector,
  editingQuestionSelector,
  QuizEditStatus,
} from 'redux/quiz-edit';
import { Skeleton } from 'antd';
import { useTranslation } from 'next-i18next';

interface MultipleChoiceContainerProps {
  question: MultipleChoiceQuestion;
  index: number;
  onDelete: () => void;
  onUpdateType: (questionId: string, value: string) => void;

  onSaveReviewEditQuestion?: (sectionIndex: number, questionIndex: number) => void;
  onFocusQuestion: (index: number) => void;
}
export const MultipleChoiceContainer = (props: MultipleChoiceContainerProps) => {
  const { question, index, onDelete, onUpdateType, onFocusQuestion } = props;
  const sectionSelected = useSelector(sectionSelectedSelector);
  const editingQuestionId = useSelector(editingQuestionSelector);
  const handleDebounce = useDebounce(200);
  const dispatch = useDispatch();
  const { t: translation } = useTranslation('common');
  const { t: formTranslation } = useTranslation('form');
  const createAnswerStatus = useSelector(createAnswerChoiceQuestionStatusSelector);
  const deleteAnswerStatus = useSelector(deleteAnswerChoiceQuestionStatusSelector);

  const onUpdateQuestion = useCallback(
    (questionId, field, value) => {
      if ((!field && !value) || !sectionSelected) return;
      const updateQuestionUseCase = getUseCase(UPDATE_QUESTION_USE_CASE, dispatch);
      const updateQuestionUseCaseInput: UpdateQuestionUseCaseInput = {
        id: questionId,
        input: {},
      };
      switch (field) {
        case 'score':
          updateQuestionUseCaseInput.input.score = parseFloat(value);
          break;
        case 'description':
          updateQuestionUseCaseInput.input.description = value;
          break;
        case 'explanation':
          updateQuestionUseCaseInput.input = {
            ...updateQuestionUseCaseInput.input,
            [field]: value,
          };
          break;
        default:
          return;
      }
      updateQuestionUseCase.execute(updateQuestionUseCaseInput);
    },
    [dispatch, sectionSelected],
  );

  const onUpdateCorrectAnswer = useCallback(
    (value) => {
      if (!value) return;
      const createUpdateSingleMultipleCorrectAnswerUseCase = getUseCase(
        UPDATE_SINGLE_MULTIPLE_CHOICE_CORRECT_ANSWER_USE_CASE,
        dispatch,
        translation,
      );
      const updateCorrectAnswerUseCaseInput: UpdateSingleMultipleChoiceCorrectAnswerUseCaseInput = {
        questionId: question.id.toString(),
        input: {
          answerIds: value,
        },
      };
      createUpdateSingleMultipleCorrectAnswerUseCase.execute(updateCorrectAnswerUseCaseInput);
    },
    [dispatch, question.id, translation],
  );

  const onUpdateAnswer = useCallback(
    (index, field, value) => {
      if (!index && !field && !value) return;
      handleDebounce(() => {
        const updateAnswerUseCase = getUseCase(
          UPDATE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
          dispatch,
        );

        const answer = question.answers[index];
        const updateAnswerUseCaseInput: UpdateSingleMultipleChoiceAnswerUseCaseInput = {
          questionId: question.id.toString(),
          answerId: answer.id.toString(),
          input: {
            [`${field}`]: value,
          },
        };
        updateAnswerUseCase.execute(updateAnswerUseCaseInput);
      });
    },
    [dispatch, handleDebounce, question],
  );

  const onDeleteAnswer = useCallback(
    (index: number) => {
      if (!index.toString()) return;
      const answer = question.answers[index];
      const deleteAnswerUseCase = getUseCase(
        DELETE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
        dispatch,
      );
      const deleteAnswerUseCaseInput: DeleteSingleMultipleChoiceAnswerUseCaseInput = {
        questionId: question.id.toString(),
        answerId: answer.id.toString(),
      };
      deleteAnswerUseCase.execute(deleteAnswerUseCaseInput);
      const updatedCorrectAnswers = question.correctAnswers
        .filter((c) => c.answerId.toString() !== answer.id.toString())
        .map((c) => c.answerId.toString());
      onUpdateCorrectAnswer([...updatedCorrectAnswers]);
    },
    [dispatch, onUpdateCorrectAnswer, question.answers, question.correctAnswers, question.id],
  );

  const onResetDeleteAnswer = useCallback(() => {
    const deleteAnswerUseCase = getUseCase(DELETE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE, dispatch);
    deleteAnswerUseCase.reset();
  }, [dispatch]);

  const onCreateAnswer = useCallback(() => {
    handleDebounce(() => {
      const createAnswerUseCase = getUseCase(
        CREATE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE,
        dispatch,
      );
      const createAnswerUseCaseInput: CreateSingleMultipleChoiceAnswerUseCaseInput = {
        questionId: question.id.toString(),
        input: {
          id: crypto.randomUUID(),
          description: `Nhập câu trả lời`,
        },
      };
      createAnswerUseCase.execute(createAnswerUseCaseInput);
    });
  }, [dispatch, handleDebounce, question.id]);

  const onResetCreateAnswer = useCallback(() => {
    const createAnswerUseCase = getUseCase(CREATE_SINGLE_MULTIPLE_CHOICE_ANSWER_USE_CASE, dispatch);
    createAnswerUseCase.reset();
  }, [dispatch]);

  const onChangeDeleteModal = useCallback(() => {
    showModalConfirm({
      title: `Xác nhận xoá câu hỏi số ${index + 1}`,
      okText: 'Xác nhận',
      cancelText: 'Huỷ',
      onOk: () => {
        onDelete();
      },
    });
  }, [index, onDelete]);

  if (!sectionSelected) return <Skeleton />;
  return (
    <MultipleChoice
      question={question}
      index={index}
      isDisabledInputs={deleteAnswerStatus === QuizEditStatus.EXECUTE}
      deleteAnswerStatus={deleteAnswerStatus}
      createAnswerStatus={createAnswerStatus}
      editingQuestionId={editingQuestionId}
      defaultDescription={formTranslation('default_question_description')}
      //question
      onDelete={onChangeDeleteModal}
      onUpdate={onUpdateQuestion}
      onUpdateType={onUpdateType}
      onFocusQuestion={onFocusQuestion}
      //answer
      onCreateAnswer={onCreateAnswer}
      onResetCreateAnswer={onResetCreateAnswer}
      onUpdateAnswer={onUpdateAnswer}
      onDeleteAnswer={onDeleteAnswer}
      onUpdateCorrectAnswer={onUpdateCorrectAnswer}
      onResetDeleteAnswer={onResetDeleteAnswer}
    />
  );
};
