import {
  FindQuestionsUseCaseQueryParams,
  FIND_ALL_QUESTIONS_USE_CASE_BY_FORM_ID,
  Form,
  FormTab,
  ResponseType,
} from '@module/form';
import { Space, Tabs } from 'antd';
import { ReactElement, useCallback, useState } from 'react';
import styled from 'styled-components';

import { styleVariables } from '@lib/common';
import { BookOutlined, InfoCircleOutlined, OrderedListOutlined } from '@ant-design/icons';
import { useTranslation } from 'next-i18next';
import { ListAssignmentByFormIdContainer } from './assignment-tab';
import { HeaderContainer } from './header';
import { EditingQuestionsTabContainer } from './question-list-tab';
import { InformationTabContainer } from './information-tab';
import { getUseCase } from '@lib/plugin-redux-core';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { heights } from '../../utils';

interface QuizEditContainerProps {
  title: string;
  form: Form;
}

const { TabPane } = Tabs;

const FormTabs = styled(Tabs)`
  height: 100%;
  .ant-tabs-content-holder {
    height: 100%;
  }
  .ant-tabs-content {
    height: calc(100vh - ${heights.header} - ${heights.pageTitle} - ${heights.tabTitle});
  }
  .ant-tabs-nav {
    margin: 0;
    height: 56px;
    .ant-tabs-nav-wrap {
      border-bottom: 1px solid ${styleVariables.colors.gray9};
      justify-content: flex-start !important;
      padding: 4px 0 0 24px;
      span {
        font-size: 16px;
      }
    }
  }
  .ant-tabs-ink-bar {
    background: ${styleVariables.colors.blue4};
    height: 4px !important;
  }
  .ant-tabs-tab {
    min-width: 218px;
    display: flex;
    justify-content: center;
    &:hover {
      color: ${styleVariables.colors.blue4};
    }
  }
  .ant-tabs-tabpane-active {
    height: 100%;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${styleVariables.colors.blue4};
  }
`;

const QuizEditContainer = ({ title, form }: QuizEditContainerProps): ReactElement => {
  const { t: translationCommon } = useTranslation('common');
  const router = useRouter();
  const { id: formId } = router.query;
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<FormTab>(FormTab.SectionDetail);
  const executeFindQuestionsUseCase = useCallback(() => {
    const findQuestionsUseCase = getUseCase(FIND_ALL_QUESTIONS_USE_CASE_BY_FORM_ID, dispatch);
    const findQuestionsUseCaseInput: FindQuestionsUseCaseQueryParams = {
      formId: formId.toString(),
      type: ResponseType.All,
    };
    findQuestionsUseCase.execute(findQuestionsUseCaseInput);
  }, [dispatch, formId]);

  const handleTabClick = useCallback(
    (key: FormTab) => {
      setActiveTab(key);
      if (key === FormTab.Assignment) {
        executeFindQuestionsUseCase();
      }
    },
    [executeFindQuestionsUseCase],
  );

  return (
    <>
      <HeaderContainer changeActiveTab={handleTabClick} formTitle={title} />
      <FormTabs centered activeKey={activeTab} size="middle" onTabClick={handleTabClick}>
        <TabPane
          tab={
            <Space direction="horizontal">
              <span>
                <OrderedListOutlined />
                {translationCommon('questions')}
              </span>
            </Space>
          }
          key={FormTab.SectionDetail}
        >
          <EditingQuestionsTabContainer />
        </TabPane>
        <TabPane
          tab={
            <Space direction="horizontal">
              <span>
                <InfoCircleOutlined />
                {translationCommon('information')}
              </span>
            </Space>
          }
          key={FormTab.GeneralInformation}
        >
          <InformationTabContainer formModel={form} />
        </TabPane>
        <TabPane
          tab={
            <Space direction="horizontal">
              <span>
                <BookOutlined />
                {translationCommon('assignment')}
              </span>
            </Space>
          }
          key={FormTab.Assignment}
        >
          <ListAssignmentByFormIdContainer />
        </TabPane>
      </FormTabs>
    </>
  );
};

export default QuizEditContainer;
