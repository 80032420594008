import { showModalConfirm } from '@lib/common';
import { useDebounce } from '@lib/common';
import { UniqueEntityID } from '@smart-form/domains';
import {
  CreateFillInBlankAnswerUseCaseInput,
  DeleteFillInBlankAnswerUseCaseInput,
  DELETE_FILL_IN_BLANK_ANSWER_USE_CASE,
  FillInBlankQuestion,
  UpdateFillInBlankAnswerUseCaseInput,
  UpdateFillInBlankCorrectAnswerUseCaseInput,
  UpdateQuestionUseCaseInput,
  UPDATE_FILL_IN_BLANK_CORRECT_ANSWER_USE_CASE,
  UPDATE_QUESTION_USE_CASE,
} from '@module/form';
import { FillInBlank } from '@module/form-components';
import { getUseCase } from '@lib/plugin-redux-core';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CREATE_FILL_IN_BLANK_ANSWER_USE_CASE,
  UPDATE_FILL_IN_BLANK_ANSWER_USE_CASE,
} from '@module/assignment';
import {
  createAnswerFillInBlankQuestionStatusSelector,
  deleteFillInBlankAnswerStatusSelector,
  updateFillInBlankAnswerStatusSelector,
  sectionSelectedSelector,
  editingQuestionSelector,
  updateFillInBlankCorrectAnswerStatusSelector,
  QuizEditStatus,
} from 'redux/quiz-edit';
import { useTranslation } from 'next-i18next';

interface FillInBlankQuestionContainerProps {
  question: FillInBlankQuestion;
  index: number;
  onDeleteQuestion: () => void;
  onUpdateTypeQuestion: (questionId: string, value: string) => void;
  previewMode?: boolean;
  onSaveReviewEditQuestion?: (sectionIndex: number, questionIndex: number) => void;
  onFocusQuestion: (index: number) => void;
}
export const FillInBlankQuestionContainer = (props: FillInBlankQuestionContainerProps) => {
  const { question, index, onDeleteQuestion, onUpdateTypeQuestion, onFocusQuestion } = props;
  const handleDebounce = useDebounce(200);
  const dispatch = useDispatch();
  const { t: formTranslation } = useTranslation('form');
  useSelector;
  const createAnswerStatus = useSelector(createAnswerFillInBlankQuestionStatusSelector);
  const deleteAnswerStatus = useSelector(deleteFillInBlankAnswerStatusSelector);
  const updateAnswerStatus = useSelector(updateFillInBlankAnswerStatusSelector);
  const updateCorrectAnswerStatus = useSelector(updateFillInBlankCorrectAnswerStatusSelector);
  const sectionSelected = useSelector(sectionSelectedSelector);
  const editingQuestionId = useSelector(editingQuestionSelector);

  const deleteFillInBlankAnswerUseCase = getUseCase(DELETE_FILL_IN_BLANK_ANSWER_USE_CASE, dispatch);

  // function handle question
  const onUpdateQuestion = useCallback(
    (questionId, field, value) => {
      if ((!index && !field && !value) || !sectionSelected) return;
      const updateQuestionUseCase = getUseCase(UPDATE_QUESTION_USE_CASE, dispatch);
      const updateQuestionUseCaseInput: UpdateQuestionUseCaseInput = {
        id: questionId,
        input: {},
      };

      switch (field) {
        case 'score':
          updateQuestionUseCaseInput.input.score = parseFloat(value);
          break;
        case 'description':
          updateQuestionUseCaseInput.input = {
            ...updateQuestionUseCaseInput.input,
            [field]: value,
          };
          break;
        case 'explanation':
          updateQuestionUseCaseInput.input = {
            ...updateQuestionUseCaseInput.input,
            [field]: value,
          };
          break;
        default:
          return;
      }
      updateQuestionUseCase.execute(updateQuestionUseCaseInput);
    },
    [dispatch, index, sectionSelected],
  );

  const onCreateAnswer = useCallback(() => {
    const createAnswer = {
      description: `Nhập câu trả lời`,
      id: new UniqueEntityID().toString(),
      questionId: question.id,
    };
    const createAnswerUseCase = getUseCase(CREATE_FILL_IN_BLANK_ANSWER_USE_CASE, dispatch);
    const createAnswerUseCaseInput: CreateFillInBlankAnswerUseCaseInput = {
      questionId: question.id.toString(),
      input: {
        ...createAnswer,
        correctAnswerId: new UniqueEntityID().toString(),
        index: question.correctAnswers.length,
      },
    };
    createAnswerUseCase.execute(createAnswerUseCaseInput);
  }, [dispatch, question.correctAnswers.length, question.id]);

  const onResetCreateAnswer = useCallback(() => {
    const createAnswerUseCase = getUseCase(CREATE_FILL_IN_BLANK_ANSWER_USE_CASE, dispatch);
    createAnswerUseCase.reset();
  }, [dispatch]);

  const onResetUpdateCorrectAnswer = useCallback(() => {
    const updateCorrectAnswer = getUseCase(UPDATE_FILL_IN_BLANK_CORRECT_ANSWER_USE_CASE, dispatch);
    updateCorrectAnswer.reset();
  }, [dispatch]);

  const onDeleteAnswer = useCallback(
    (answerIndex: number) => {
      const answer = question.answers[answerIndex];
      const deleteAnswerUseCaseInput: DeleteFillInBlankAnswerUseCaseInput = {
        questionId: question.id.toString(),
        answerId: answer.id.toString(),
      };
      deleteFillInBlankAnswerUseCase.execute(deleteAnswerUseCaseInput);
    },
    [deleteFillInBlankAnswerUseCase, question.answers, question.id],
  );

  const onResetDeleteAnswer = useCallback(() => {
    deleteFillInBlankAnswerUseCase.reset();
  }, [deleteFillInBlankAnswerUseCase]);

  const onUpdateAnswer = useCallback(
    (index, value) => {
      handleDebounce(() => {
        const updateAnswerUseCase = getUseCase(UPDATE_FILL_IN_BLANK_ANSWER_USE_CASE, dispatch);
        const answer = question.answers[index];
        const updateAnswerUseCaseInput: UpdateFillInBlankAnswerUseCaseInput = {
          questionId: question.id.toString(),
          answerId: answer.id.toString(),
          input: {
            ...answer,
            description: value,
          },
        };
        updateAnswerUseCase.execute(updateAnswerUseCaseInput);
      });
    },
    [dispatch, handleDebounce, question.answers, question.id],
  );

  const onUpdateCorrectAnswer = useCallback(
    (index, value, field) => {
      handleDebounce(() => {
        const correctAnswer = question.correctAnswers[index];
        const updateFillInBlankCorrectAnswerUseCase = getUseCase(
          UPDATE_FILL_IN_BLANK_CORRECT_ANSWER_USE_CASE,
          dispatch,
        );

        const updateFillInBlankCorrectAnswerUseCaseInput: UpdateFillInBlankCorrectAnswerUseCaseInput =
          {
            questionId: question.id.toString(),
            correctAnswerId: correctAnswer.id.toString(),
            input: {},
          };

        if (field === 'answerScore') {
          updateFillInBlankCorrectAnswerUseCaseInput.input.score = parseFloat(value);
        } else {
          updateFillInBlankCorrectAnswerUseCaseInput.input.score =
            question.correctAnswers[index].score;
        }

        updateFillInBlankCorrectAnswerUseCase.execute(updateFillInBlankCorrectAnswerUseCaseInput);
        onResetUpdateCorrectAnswer();
      });
    },
    [handleDebounce, question, dispatch, onResetUpdateCorrectAnswer],
  );

  const onChangeDeleteModal = useCallback(() => {
    showModalConfirm({
      title: `Xác nhận xoá câu hỏi số ${index + 1}`,
      okText: 'Xác nhận',
      cancelText: 'Huỷ',
      onOk: () => {
        onDeleteQuestion();
      },
    });
  }, [index, onDeleteQuestion]);

  return (
    <FillInBlank
      question={question as FillInBlankQuestion}
      index={index}
      editingQuestionId={editingQuestionId}
      isDisabledInputs={deleteAnswerStatus === QuizEditStatus.EXECUTE}
      //question
      onUpdateType={onUpdateTypeQuestion}
      onDelete={onChangeDeleteModal}
      onUpdate={onUpdateQuestion}
      onFocusQuestion={onFocusQuestion}
      defaultDescription={formTranslation('default_question_description')}
      //answer
      onCreateAnswer={onCreateAnswer}
      createAnswerStatus={createAnswerStatus}
      onResetCreateAnswer={onResetCreateAnswer}
      onDeleteAnswer={onDeleteAnswer}
      deleteAnswerStatus={deleteAnswerStatus}
      onResetDeleteAnswer={onResetDeleteAnswer}
      onUpdateAnswer={onUpdateAnswer}
      onUpdateCorrectAnswer={onUpdateCorrectAnswer}
      updateCorrectAnswerStatus={updateCorrectAnswerStatus}
      updateAnswerStatus={updateAnswerStatus}
    />
  );
};
