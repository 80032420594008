import { Question, QuestionProps, Section } from '@module/form';
import { useSelector } from 'react-redux';
import { SubmissionSectionComponent, SubmissionSectionLabel } from '@module/form-components';
import {
  loadingQuestionsSelector,
  questionsSelector,
  selectedSectionIndexSelector,
  selectedSectionSelector,
} from '../../../redux/quiz-preview';
import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { Skeleton } from 'antd';

export const SectionPreviewContainer = () => {
  // Define configs
  const { t: translation } = useTranslation('common');

  // Get Selectors
  const selectedSection: Section = useSelector(selectedSectionSelector);
  const selectedSectionIndex: number = useSelector(selectedSectionIndexSelector);
  const isLoadingQuestions: boolean = useSelector(loadingQuestionsSelector);
  const questions: Question<QuestionProps>[] = useSelector(questionsSelector);

  // Define logic variable
  const totalScore = useMemo(() => {
    if (questions) {
      return questions.reduce((score, question) => score + question.score, 0);
    }
  }, [questions]);

  const label: SubmissionSectionLabel = useMemo(() => {
    return {
      section: translation('section'),
      score: translation('score'),
      readMore: translation('readMore'),
    };
  }, [translation]);

  if (isLoadingQuestions === undefined || isLoadingQuestions === true) {
    return <Skeleton />;
  }

  return (
    <SubmissionSectionComponent
      section={selectedSection}
      sectionIndex={selectedSectionIndex}
      totalScore={totalScore}
      label={label}
      disable
    />
  );
};
