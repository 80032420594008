import moment from "moment";
/* eslint-disable no-loops/no-loops */

export var getPastTime = function getPastTime() {
  var hours = [];
  var minutes = [];

  for (var i = 0; i < moment().hour(); i += 1) {
    hours.push(i);
  }

  for (var _i = 0; _i < moment().minutes(); _i += 1) {
    minutes.push(_i);
  }

  return {
    hours: hours,
    minutes: minutes
  };
};
export var disabledDate = function disabledDate(current) {
  return current && current < moment().startOf('day');
};
export var disabledTime = function disabledTime(selectedDate) {
  return {
    disabledHours: !(selectedDate !== null && selectedDate !== void 0 && selectedDate.isAfter(moment.now(), 'days')) && function () {
      return getPastTime().hours;
    },
    disabledMinutes: !(selectedDate !== null && selectedDate !== void 0 && selectedDate.isAfter(moment.now(), 'hours')) && function () {
      return getPastTime().minutes;
    }
  };
};