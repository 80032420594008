import { Form } from '@module/form';
import { Typography, Row, Col } from 'antd';
import { ReactElement } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { formSelector } from 'redux/quiz-preview';
import { styleVariables } from '@lib/common';
import {
  horizontalColumnGutter,
  verticalColumnGutter,
  listQuestionColumnSpan,
} from '../quiz-preview.container';

const { Title } = Typography;

export const pageHeaderHeight = 75;

const PageHeaderWrapper = styled.div`
  height: ${pageHeaderHeight}px;
  background: transparent;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
`;

const StyledRow = styled(Row)`
  display: flex;
  align-items: center;

  height: 100%;
`;

const Content = styled.div`
  float: right;

  @media (min-width: 1024px) {
    width: 560px;
  }

  @media (min-width: 1280px) {
    width: 625px;
  }

  @media (min-width: 1440px) {
    width: 720px;
  }

  @media (min-width: 1920px) {
    width: 1030px;
  }
`;

const FormTitle = styled(Title)`
  &.ant-typography {
    margin-bottom: 0;

    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;

    color: ${styleVariables.colors.gray13};
  }
`;

export const QuizPageHeaderPreviewContainer = (): ReactElement => {
  // Get data from selector
  const form: Form = useSelector(formSelector);

  return (
    <PageHeaderWrapper>
      <StyledRow gutter={[horizontalColumnGutter, verticalColumnGutter]}>
        <Col span={listQuestionColumnSpan}>
          <Content>
            <FormTitle level={2}>{form.title}</FormTitle>
          </Content>
        </Col>
      </StyledRow>
    </PageHeaderWrapper>
  );
};
